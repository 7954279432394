import React, { useState, useEffect } from "react";
import UserManagementNavbar from "../userManagementNavbar";
import { Container, Form, Row, Col } from "react-bootstrap";
import Back from "../../../common/components/back";
import "../UserManagementParentChildDetails/userManagementParentChildDetails.css";
import "../UserManagementClinicianDetails/userManagementClinicianDetails.css";
import "../UserManagementClsDetails/userManagementClsDetails.css";
import Footer from "../../Footer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import moment from "moment";
import { ROUTES_PATH } from "../../../constant";

import { Redirect } from "react-router";
import MultiSelect from "../multiSelect";

import { areasOfInterestOptions } from "../../../utils/options";
import DeleteWarning from "../../commonComponent/deleteWarning";

const UserManagementClsDetails = (props) => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState();
  const [data, setData] = useState({
    id: "",
    name: "",
    image: "",
    email: "",
    gender: "",
    birth_date: "",
    practice_type: "",
    phone_number: "",
    certification_id: "",
    areas_of_interest: [],
    imageHash: "",
  });

  useEffect(() => {
    setData({ ...data, ...props.details });
  }, []);

  useEffect(() => {
    if (value) {
      var names = value.map(function (item) {
        return item["value"];
      });

      setData({ ...data, areas_of_interest: names });
    }
  }, [value]);

  useEffect(() => {
    if (props.details.areas_of_interest) {
      setValue(
        props.details.areas_of_interest.map((str, index) => ({
          value: str,
          label: str,
        }))
      );
    }
  }, [props.match.params.path]);

  if (Object.keys(props.details).length == 0) {
    return <Redirect to={ROUTES_PATH.USER_MANAGEMENT} />;
  }

  const handleClose = () => {
    setShow(false);
    handleDeleteButton();
  };
  const handleCancelClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleDeleteButton = () => {
    props.deleteCls(data.id);
  };

  const handleBack = () => {
    props.history.push(ROUTES_PATH.USER_MANAGEMENT);
  };

  const handleActivateButton = () => {
    props.activation(data.id, {
      status: props.details.status === "ACTIVE" ? "DELETED" : "ACTIVE",
    });
  };

  let todays_date = new Date();
  todays_date = todays_date.toISOString().split("T")[0];

  return (
    <Container
      style={{ maxWidth: "1046px", minHeight: "100vh", position: "relative" }}
    >
      <Back
        Back={handleBack}
        backIconClassName={"parent-back-icon"}
        backNavItemClassName={"parent-back-nav"}
        className="parent-back"
      />

      <UserManagementNavbar
        title={"CLS Details"}
        isEditButton={true}
        isDeleteButton={true}
        handleDeleteButton={handleShow}
        id={"parent-nav-title"}
        className={"parent-navbar"}
        activationLabel={
          props.details.status === "ACTIVE" ? "Deactivate" : "Activate"
        }
        handleEditButton={handleActivateButton}
      />

      <Form noValidate>
        <Form.Group style={{ paddingTop: "7px" }}>
          <label htmlFor="upload-button">
            {data.image ? (
              <img src={`${data.image}`} alt="" className="preview-image" />
            ) : (
              <div className="preview-image-another">
                {data.name.toUpperCase()[0]}
              </div>
            )}
          </label>
          <input
            disabled={true}
            type="file"
            id="upload-button"
            style={{ display: "none" }}
          />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Name</Form.Label>
              <Form.Control
                value={data.name}
                readOnly={true}
                className="parent-name-field"
                type="text"
                placeholder="CLS Name"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Email ID</Form.Label>
              <Form.Control
                value={data.email}
                readOnly={true}
                className="parent-name-field"
                type="email"
                placeholder="dummy@email.com"
              />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Gender</Form.Label>

              <Form.Control
                value={data.gender}
                disabled={true}
                className="parent-name-field"
                type="text"
                placeholder="Female"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Birth Date</Form.Label>
              <Form.Control
                value={
                  data.birth_date
                    ? moment(data.birth_date).format("MM/DD/YYYY")
                    : ""
                }
                readOnly={true}
                className="parent-name-field"
                max={todays_date}
                placeholder="MM/DD/YYYY"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Areas of Interest</Form.Label>
              <MultiSelect
                disabled={true}
                multi={true}
                value={value}
                placeholder={"None"}
                options={areasOfInterestOptions}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Type of Work</Form.Label>
              <Form.Control
                value={data.practice_type}
                readOnly={true}
                className="parent-name-field"
                type="text"
                placeholder="Private Practice"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                CLS Certification Number
              </Form.Label>
              <Form.Control
                value={data.certification_id}
                readOnly={true}
                className="parent-name-field"
                type="text"
                placeholder="#349823566"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Phone Number</Form.Label>
              <Form.Control
                value={data.phone_number ? data.phone_number : "-"}
                readOnly={true}
                className="parent-name-field"
                type="tel"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Unit</Form.Label>
              <Form.Control
                disabled={true}
                value={data.hospital_name}
                className="parent-name-field"
                type="text"
                placeholder="NA"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">Pediatric Hospital</Form.Label>
              <Form.Control
                disabled={true}
                value={data.pediatric_hospital ? data.pediatric_hospital : "-"}
                className="parent-name-field"
                type="text"
                placeholder="NA"
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>

      <Footer className={"cls-footer"} />

      <DeleteWarning
        show={show}
        handleCancelClose={handleCancelClose}
        handleClose={handleClose}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  try {
    return {
      details: state.userManagementDetails.details,
    };
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset: actionCreator.reset,

      deleteCls: actionCreator.deleteCls,
      activation: actionCreator.activation,
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementClsDetails);
