import React, { useEffect, useState } from "react";
import ContentButton from "../contentButton";
import ImageUpload from "../ImageUpload";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Form, Row } from "react-bootstrap";
import { MESSAGE, USER_GENDER_DROPDOWN } from "../../../constant";
import "../DailyQuotes/dailyQuotes.css";
import "../../UserManagement/UserManagementClinicianDetails/userManagementClinicianDetails.css";
import AddModalHoc from "../AddModalHoc/addModalHoc";
import * as actionCreator from "../../../modules/actions";
import { validateChar } from "../../../utils/validations";
import MultiSelect from "../../UserManagement/multiSelect";
import { toast } from "react-toastify";
import { rename } from "../../../utils/rename";
import { components } from "react-select";
import { clone } from "lodash";

const MenuList = props => {
  return (
    <components.MenuList {...props}>
      <div className="select-style">
        You can select multiple options from the list of hospitals{" "}
      </div>
      {props.children}
    </components.MenuList>
  );
};

const Option = props => {
  return (
    <components.Option {...props}>
      <div>
        {props.children}
        <input
          className="option-custom-input"
          type="checkbox"
          checked={props.isSelected}
          onChange={e => null}
        ></input>
      </div>
    </components.Option>
  );
};

const AddResourcesCategories = props => {
  const [titleInvalid, setTitleInvalid] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);

  const contentDetails = useSelector(state => state.contentDetails)

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState();
  const [hospitalInvalid, setHospitalInvalid] = useState(false);

  const [data, setData] = useState({
    title: "",
    image: null,
    category_type: null,
    hospital: [],
    hospitalList: [],
  });

  const optionsCategory = [
    { label: "Hospital", value: "Hospital" },
    { label: "Parent", value: "Parent" },
    { label: "Proessional", value: "Professional" },
  ];

  useEffect(()=>{
    if( props.isEdit){

      let hospitalArray = [];
      let hospitalIdArray = [];

      if (
        props.contentDetails.hospital &&
        props.contentDetails.hospital.length > 0 &&
        options?.length > 0
      ) {
        props.contentDetails.hospital &&
          props.contentDetails.hospital.forEach(hospital => {
            options.map(option => {
              if (option.value === parseInt(hospital)) {
                hospitalArray.push(option);
                hospitalIdArray.push(option.value);
              }
            });
          });
        }
      setData({
        id: contentDetails?.id,
        title: contentDetails?.cellOne,
        image: contentDetails?.thumbnail_img,
        category_type:{label: contentDetails?.category, value: contentDetails?.category },
        hospitalList:
          hospitalArray.length === options?.length - 1
            ? [...hospitalArray, { label: "All", value: "all" }]
            : hospitalArray,
        hospital: hospitalIdArray,
      });
    }else{
      setData({
        ...data,
        hospital : props.userRole.includes("ADMIN") ? data.hospital : [props.hospitalDetails.id]
      })
    }
  },[contentDetails, options])



  useEffect(() => {
    props.getFullHospitals();
  }, []);

  useEffect(() => {

    setOptions(
      [
        ...rename(props.fullHospitals.data, "value", "label", "id", "name"),
        { label: "All", value: "all" },
      ].sort((a, b) =>
        (a["label"] || "")
          .toString()
          .localeCompare((b["label"] || "").toString())
      )
    );
  }, [props.fullHospitals.data]);

  const isIncludingString = (string, option) => {
    let result = false;
    if (
      !string ||
      option.label.toString().includes(string) ||
      option.value.toString().includes(string)
    ) {
      result = true;
    }
    return result;
  };
  
  const setHospital = (opt, { option }) => {
    let newOpts = opt;
    let string = newOpts.value;
    let hospitalArray = [];
    let hospitalDetailsArray = [];
    if (data.hospital?.length === 0) {
      data.hospitalList &&
        data.hospitalList.map(hopital => {
          hospitalArray.push(Number(hopital.id));
          hospitalDetailsArray.push(hopital);
        });
    }
    if (
      data.hospitalList?.pop()?.label === "All" &&
      option &&
      option.value === "all"
    ) {
      hospitalArray = [];
      hospitalDetailsArray = [];
    } else if (option && option.value === "all") {
      let filteredOptions = clone(options);
      filteredOptions = filteredOptions.filter(
        filteredOption =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption)
      );
      newOpts = newOpts
        .concat(filteredOptions)
        .filter(newOpt => newOpt.value !== "all");
      newOpts.map(val => {
        hospitalArray.push(Number(val.value));
        hospitalDetailsArray.push(val);
      });
    } else {
      opt.map(obj => {
        hospitalArray.push(Number(obj.value));
        hospitalDetailsArray.push(obj);
      });
    }
    setHospitalInvalid(false);
    const is_same = () => {
      let count = 0;
      hospitalDetailsArray.map(obj => {
        if (options.includes(obj)) {
          count = count + 1;
        }
      });
      return count;
    };
    if (is_same() === options.length - 1) {
      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [
          ...new Set([...hospitalDetailsArray, { label: "All", value: "all" }]),
        ],
      });
    } else {
      hospitalDetailsArray = hospitalDetailsArray.filter(
        item => item.value !== "all"
      );

      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [...new Set(hospitalDetailsArray)],
      });
    }
  };

  const handleSubmit = e => {
    let validatedHospital = data.hospital.length > 0;
    e.preventDefault();

    if (!validatedHospital) {
      setHospitalInvalid(true);
    }

    setSubmittedOnce(true);
    if (!data.category_type || !data.image || 
      !validatedHospital) {
      return;
    }

    if (loading) {
      toast.error(MESSAGE.UPLOAD_PROGRESS, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    props.toggleMainLoader(true);

    if(props.isEdit){

      if( contentDetails.category === "Hospital"){
        props.updateHospitalCategory(
          data.id,{
          title: data.title,
          thumbnail_img: data.image,
          hospital: data.hospital,
        });
      }
      else if(contentDetails.category === "Parent"){
        props.updateParentCategory(
          data.id,{
          title: data.title,
          thumbnail_img: data.image,
          hospital: data.hospital,
        });
      }
      else {
        props.updateProfessionalCategory(
          data.id,{
          title: data.title,
          thumbnail_img: data.image,
          hospital: data.hospital,
        });
      }
    }
    else {
      if (data.category_type?.value === "Hospital") {
        props.addResources({
          title: data.title,
          thumbnail_img: data.image,
          hospital: data.hospital,
        });
      } else if (data.category_type?.value === "Parent") {
        props.addParentResources({
          title: data.title,
          thumbnail_img: data.image,
          hospital: data.hospital,
        });
      } else {
        props.addProfessionalResources({
          title: data.title,
          thumbnail_img: data.image,
          hospital: data.hospital,
        });
      }
    }
    
  };

  const styles = {
    control: (base, state) => ({
      ...base,
      border: submittedOnce
        ? !data.category_type
          ? "1px solid #eb5757"
          : 0
        : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(0 123 255 / 25%)" : 0,
      "&:hover": {
        border: submittedOnce
          ? !data.category_type
            ? "1px solid #eb5757"
            : 0
          : 0,
      },
    }),
    valueContainer: (base, state) => ({
      ...base,
      width: "100%",
      maxWidth: "552px",
      height: "60px",
      backgroundColor: "#f4f4f4",
      borderRadius: "8px",
      boxSizing: "border-box",
      border: "none",
      backgroundImage: `url(${USER_GENDER_DROPDOWN})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "95% 50%",
      marginTop: "2px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#AEB0B5",
    }),
    
    placeholder: (base, state) => ({
      ...base,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#AEB0B5",
    }),
    indicatorsContainer: (base, state) => ({
      ...base,
      display: "none",
  
      backgroundColor: "#f4f4f4",
  
      border: "none",
      backgroundImage: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#AEB0B5",
      outline: "none",
    }),
 
    option: (base, state) => ({
      ...base,
      borderBottom: "1px solid #AEB0B5",
      color: "#455674",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      paddingTop: "16px",
      paddingBottom: "16px",
      backgroundColor: "none",
      paddingLeft: "20px",
    }),
  };

  return (
    <>
      <Form noValidate>

      {props.userRole.includes("ADMIN") ?
          <Row style={{ marginTop: "24px" }}>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                Hospital<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <MultiSelect
                multi={true}
                placeholder={"Select Hospital"}
                options={options}
                styles={styles}
                components={{ MenuList, Option }}
                className="select-hospital-class"
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                onChange={setHospital}
                value={data.hospitalList}
              />
              {submittedOnce ? (
                hospitalInvalid ? (
                  <p className="clinician-invalid">Please choose a category</p>
                ) : null
              ) : null}
            </Form.Group>
          </Col>
        </Row> : 
        
        <Row style={{ marginTop: "24px" }}>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                Hospital<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <MultiSelect
                multi={true}
                placeholder={"Select Hospital"}
                options={options}
                styles={styles}
                className="select-hospital-class"
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                value={[{"label":props.hospitalDetails.name, "value":props.hospitalDetails.name}]}
                disabled = {true}
              />
              {submittedOnce ? (
                hospitalInvalid ? (
                  <p className="clinician-invalid">Please choose a category</p>
                ) : null
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        }

        <Row>
          <Col>
            <Form.Group controlId="formGroupEmail">
              <Form.Label className="parent-name">
                Select Category Type
                <span style={{ color: "red" }}> *</span>
              </Form.Label>
              <MultiSelect
                multi={false}
                placeholder={"Select Category"}
                options={optionsCategory}
                styles={styles}
                className="select-hospital-class"
                onChange={e => {
                  setData({
                    ...data,
                    category_type: { label: e.label, value: e.value },
                  });
                }}
                value={data.category_type}
              />
              {submittedOnce ? (
                !data.category_type ? (
                  <p className="clinician-invalid">
                    Please choose a category type
                  </p>
                ) : null
              ) : null}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="formGroupEmail">
              <Form.Label className="modal-content-label">
                Category Title <span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Type Or Paste Your Resource Title"
                className="input-field-content"
                value={data.title}
                maxLength="30"
                onChange={e => {
                  setData({ ...data, title: e.target.value });
                  submittedOnce
                    ? setTitleInvalid(!validateChar(e.target.value))
                    : setTitleInvalid(false);
                }}
                isInvalid={titleInvalid}
              />
              <Form.Control.Feedback
                type="invalid"
                className="clinician-invalid"
              >
                {MESSAGE.TITLE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
            <div style={{ marginBottom: "10px" }}>
              <div className="chracter-remain">
                {" "}
                {30 - data.title.length}/30 Characters
              </div>
            </div>
          </Col>
        </Row>

        <ImageUpload
          title={"Upload Image"}
          optional={false}
          data={data}
          setData={res => {
            setData({ ...data, image: res.location });
          }}
          handleImageDelete={() => {
            setData({ ...data, image: null });
          }}
          loading={loading}
          setLoading={setLoading}
        />
        {submittedOnce ? (
          !data.image ? (
            <p className="clinician-invalid">Please upload valid image</p>
          ) : null
        ) : (
          <Form.Text className="text-muted" style={{ marginTop: "-10px" }}>
            **Please Add JPG or PNG. Pictures should be aligned vertically. If posted horizontal, pictures will rotate when exported.
          </Form.Text>
        )}

        <Row>
          <Col></Col>
          <Col className="mt-auto">
            <ContentButton
              onClick={handleSubmit}
              title={"Publish Category"}
              className={"podcast-button"}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

const mapStateToProps = state => {
  try {
    return {
      user: state.user.profile,
      details: state.userManagementDetails.details,
      loader: state.loader.mainLoader,
      resourcesCategory: state.resourcesCategory,
      contentDetails: state.contentDetails,
      fullHospitals: state.fullHospitals,
      userRole: state.user.profile.role,
      hospitalDetails: state.hospitalDetails,
    };
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reset: actionCreator.reset,
      toggleMainLoader: actionCreator.toggleMainLoader,
      addResources: actionCreator.addHospitalCategories,
      addParentResources: actionCreator.addParentCategories,
      addProfessionalResources: actionCreator.addProfessionalCategories,
      updateHospitalCategory : actionCreator.updateHospitalCategory,
      updateParentCategory : actionCreator.updateParentCategory,
      updateProfessionalCategory : actionCreator.updateProfessionalCategory,
      getFullHospitals: actionCreator.getFullHospitals,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddModalHoc(AddResourcesCategories));
