import React, { useState, useEffect } from "react";
import TextField from "../textField";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import { Container, Row, Col, Form } from "react-bootstrap";
import DateField from "../dateField";
import { ROUTES_PATH, MESSAGE, USER_GENDER_DROPDOWN } from "../../../constant";
import Back from "../../../common/components/back";
import "../DailyQuotes/dailyQuotes.css";
import "./procedures.css";
import moment from "moment";
import Footer from "../../Footer";
import { validateChar, validateTextField } from "../../../utils/validations";
import UploadMedia from "../../commonComponent/uploadMedia";
import {
  replace,
  separatePictures,
  separateVideo,
  status,
} from "../../../utils/rename";
import ContentButton from "../contentButton";
import { toast } from "react-toastify";
import { components } from "react-select";
import MultiSelect from "../../UserManagement/multiSelect";
import { rename } from "../../../utils/rename";
import { clone } from "lodash";

const MenuList = props => {
  return (
    <components.MenuList {...props}>
      <div className="select-style">
        You can select multiple options from the list of hospitals{" "}
      </div>
      {props.children}
    </components.MenuList>
  );
};

const Option = props => {
  return (
    <components.Option {...props}>
      <div>
        {props.children}
        <input
          className="option-custom-input"
          type="checkbox"
          checked={props.isSelected}
          onChange={e => null}
        ></input>
      </div>
    </components.Option>
  );
};

const AddProcedures = props => {
  const { path, role } = props;
  const [media, setMedia] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [nameInvalid, setNameInvalid] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [whatTextInvalid, setWhatInvalid] = useState(false);
  const [sequenceInvalid, setSequenceInvalid] = useState(false);
  const [advocateInvalid, setAdvocateInvalid] = useState(false);
  const [hospitalInvalid, setHospitalInvalid] = useState(false);
  const [whyTextInvalid, setWhyInvalid] = useState(false);
  const [loader, setLoader] = useState(false);
  const [reelLoader, setReelLoader] = useState(false);

  const [data, setData] = useState({
    user: "",
    for_role: "",
    name: "",
    pictures: [],
    videos: [],
    why_text: "",
    what_text: "",
    how_to_advocate_text: "",
    explain_to_child: "",
    sequence_of_events_text: [],
    Children_highlight_reel: "",
    scheduled_date: "",
    status: "",
    hospital: [],
    hospitalList: [],
  });

  const [options, setOptions] = useState();

  useEffect(() => {
    props.getFullHospitals();
  }, []);

  useEffect(() => {
    setData({
      ...data,
      hospital : props.userRole.includes("ADMIN") ? data.hospital : [props.hospitalDetails.id]
    })
  }, []);

  useEffect(() => {
    setOptions(
      [
        ...rename(props.fullHospitals.data, "value", "label", "id", "name"),
        { label: "All", value: "all" },
      ].sort((a, b) =>
        (a["label"] || "")
          .toString()
          .localeCompare((b["label"] || "").toString())
      )
    );
  }, [props.fullHospitals.data]);

  if (props.responseSuccess) {
    props.history.push(path ? path : ROUTES_PATH.PROCEDURES);
  }

  const handleBack = () => {
    props.history.push(path ? path : ROUTES_PATH.PROCEDURES);
  };

  const handleImageDelete = (e, index) => {
    e.preventDefault();
    const arr = [...media];
    arr.splice(index, 1);
    setMedia(arr);
  };

  const handleReelDelete = e => {
    e.preventDefault();
    setData({ data, Children_highlight_reel: "" });
  };
  const isIncludingString = (string, option) => {
    let result = false;
    if (
      !string ||
      option.label.toString().includes(string) ||
      option.value.toString().includes(string)
    ) {
      result = true;
    }
    return result;
  };

  const setHospital = (opt, { option }) => {
    let newOpts = opt;
    let string = newOpts.value;
    let hospitalArray = [];
    let hospitalDetailsArray = [];
    if (data.hospital?.length === 0) {
      data.hospitalList &&
        data.hospitalList.map(hopital => {
          hospitalArray.push(Number(hopital.id));
          hospitalDetailsArray.push(hopital);
        });
    }
    if (
      data.hospitalList?.pop()?.label === "All" &&
      option &&
      option.value === "all"
    ) {
      hospitalArray = [];
      hospitalDetailsArray = [];
    } else if (option && option.value === "all") {
      let filteredOptions = clone(options);
      filteredOptions = filteredOptions.filter(
        filteredOption =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption)
      );
      newOpts = newOpts
        .concat(filteredOptions)
        .filter(newOpt => newOpt.value !== "all");
      newOpts.map(val => {
        hospitalArray.push(Number(val.value));
        hospitalDetailsArray.push(val);
      });
    } else {
      opt.map(obj => {
        hospitalArray.push(Number(obj.value));
        hospitalDetailsArray.push(obj);
      });
    }
    setHospitalInvalid(false);
    const is_same = () => {
      let count = 0;
      hospitalDetailsArray.map(obj => {
        if (options.includes(obj)) {
          count = count + 1;
        }
      });
      return count;
    };
    if (is_same() === options.length - 1) {
      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [
          ...new Set([...hospitalDetailsArray, { label: "All", value: "all" }]),
        ],
      });
    } else {
      hospitalDetailsArray = hospitalDetailsArray.filter(
        item => item.value !== "all"
      );

      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [...new Set(hospitalDetailsArray)],
      });
    }
  };

  const handleAdd = e => {
    e.preventDefault();
    setSubmittedOnce(true);
    let validatedName = validateChar(data.name);
    let validatedText = validateTextField(data.what_text);
    let validatedWhyText = validateTextField(data.why_text);
    let validatedSequence = validateTextField(data.sequence_of_events_text);
    let validatedAdvocate = validateTextField(data.how_to_advocate_text);
    let validatedHospital = data.hospital.length > 0;
    if (!validatedName) {
      setNameInvalid(true);
    }
    if (!validatedText) {
      setWhatInvalid(true);
    }
    if (!validatedSequence) {
      setSequenceInvalid(true);
    }
    if (!validatedHospital) {
      setHospitalInvalid(true);
    }
    if (!validatedAdvocate) {
      setAdvocateInvalid(true);
    }
    if (!validatedWhyText) {
      setWhyInvalid(true);
    }
    if (loader || reelLoader) {
      toast.error(MESSAGE.UPLOAD_PROGRESS, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (
      !validatedName ||
      !validatedText ||
      !validatedSequence ||
      !validatedAdvocate ||
      !validatedWhyText ||
      loader ||
      reelLoader ||
      !selectedDate ||
      !validatedHospital
    ) {
      return;
    }

    props.toggleMainLoader(true);
    props.addProcedure({
      user: props.user.idToken,
      for_role: role ? role : "PARENT",
      name: data.name,
      pictures:
        Array.isArray(separatePictures(media)) && separatePictures(media).length
          ? separatePictures(media)
          : null,
      videos:
        Array.isArray(separateVideo(media)) && separateVideo(media).length
          ? separateVideo(media)
          : null,

      what_text: data.what_text,
      why_text: data.why_text,
      how_to_advocate_text: data.how_to_advocate_text,
      explain_to_child: data.explain_to_child ? data.explain_to_child : null,
      sequence_of_events_text: data.sequence_of_events_text
        ? data.sequence_of_events_text.split(";")
        : "",
      Children_highlight_reel: data.Children_highlight_reel
        ? data.Children_highlight_reel
        : null,
      scheduled_date: selectedDate
        ? moment(selectedDate).format("YYYY-MM-DD")
        : "",
      status: status(selectedDate),
      hospital: data.hospital,
    });
  };

  const styles = {
    control: (base, state) => ({
      ...base,
      border: submittedOnce
        ? data.hospital.length === 0
          ? "1px solid #eb5757"
          : 0
        : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(0 123 255 / 25%)" : 0,
      "&:hover": {
        border: submittedOnce
          ? data.hospital.length === 0
            ? "1px solid #eb5757"
            : 0
          : 0,
      },
    }),
    valueContainer: (base, state) => ({
      ...base,
      width: "100%",
      maxWidth: "452px",
      height: "60px",
      backgroundColor: "#f4f4f4",
      borderRadius: "8px",
      boxSizing: "border-box",
      border: "none",
      backgroundImage: `url(${USER_GENDER_DROPDOWN})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "95% 50%",
      marginTop: "2px",
    }),

    indicatorsContainer: (base, state) => ({
      ...base,
      display: "none",

      backgroundColor: "#f4f4f4",

      border: "none",
      backgroundImage: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#5b616b",
      outline: "none",
    }),
    option: (base, state) => ({
      ...base,
      borderBottom: "1px solid #AEB0B5",
      color: "#455674",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      paddingTop: "16px",
      paddingBottom: "16px",
      backgroundColor: "none",
      paddingLeft: "20px",
    }),
  };

  return (
    <>
      <Container
        style={{ maxWidth: "1046px", minHeight: "100vh", position: "relative" }}
      >
        <Back
          Back={handleBack}
          backIconClassName={"parent-back-icon"}
          backNavItemClassName={"parent-back-nav"}
          className="parent-back"
        />

        <Form noValidate>
          <Row style={{ marginTop: "27px" }}>
            <Col>
              <p className="title-add-procedure">Add Procedure</p>
              {props.userRole.includes("ADMIN") ? <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                Hospital<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <MultiSelect
                multi={true}
                placeholder={"Select Hospital"}
                options={options}
                styles={styles}
                components={{ MenuList, Option }}
                className="select-hospital-class"
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                onChange={setHospital}
                value={data.hospitalList}
              />
              {hospitalInvalid ? (
                <p className="clinician-invalid">Please choose a category</p>
              ) : null}
            </Form.Group>:
            
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                Hospital<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <MultiSelect
                multi={true}
                placeholder={"Select Hospital"}
                styles={styles}
                components={{ MenuList, Option }}
                className="select-hospital-class"
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                value={[{"label":props.hospitalDetails.name, "value":props.hospitalDetails.name}]}
                disabled = {true}
              />
              {hospitalInvalid ? (
                <p className="clinician-invalid">Please choose a category</p>
              ) : null}
            </Form.Group>}
              <Form.Group>
                <Form.Label className="procedure-content-label">
                  Name<span style={{ color: "red" }}> *</span>
                </Form.Label>
                <Form.Control
                  className="input-field-content"
                  type="text"
                  placeholder="Enter Procedure Name"
                  isInvalid={nameInvalid}
                  onChange={e => {
                    setData({ ...data, name: e.target.value });
                    submittedOnce
                      ? setNameInvalid(
                          !validateChar(e.target.value) ? true : false
                        )
                      : setNameInvalid(false);
                  }}
                />
                <Form.Control.Feedback
                  className="clinician-invalid"
                  type="invalid"
                >
                  {MESSAGE.NAME_INVALID}
                </Form.Control.Feedback>
              </Form.Group>

              <TextField
                label={"Understanding the procedure:"}
                labelClassName={"textfield-content-label"}
                className={"text-field-content"}
                isInvalid={whatTextInvalid}
                onChange={e => {
                  setData({ ...data, what_text: e.target.value });
                  submittedOnce
                    ? setWhatInvalid(
                        !validateTextField(replace(e.target.value))
                          ? true
                          : false
                      )
                    : setWhatInvalid(false);
                }}
                message={MESSAGE.PROCEDURE_INVALID}
              />

              <TextField
                label={"The Purpose Behind It:"}
                labelClassName={"textfield-content-label"}
                className={"text-field-content"}
                isInvalid={whyTextInvalid}
                onChange={e => {
                  setData({ ...data, why_text: e.target.value });
                  submittedOnce
                    ? setWhyInvalid(
                        !validateTextField(replace(e.target.value))
                          ? true
                          : false
                      )
                    : setWhyInvalid(false);
                }}
                message={MESSAGE.REASON_INVALID}
              />

              <TextField
                label={"Step-by-Step Guide:"}
                labelClassName={"textfield-content-label"}
                className={"text-field-content"}
                isInvalid={sequenceInvalid}
                onChange={e => {
                  setData({
                    ...data,
                    sequence_of_events_text: e.target.value,
                  });
                  submittedOnce
                    ? setSequenceInvalid(
                        !validateTextField(replace(e.target.value))
                          ? true
                          : false
                      )
                    : setSequenceInvalid(false);
                }}
                message={MESSAGE.SEQUENCE_INVALID}
              />
              <Form.Text className="text-muted" style={{ marginTop: "-10px" }}>
                Please enter semicolon separated sequence of events
              </Form.Text>

              <TextField
                label={"Supporting Your Child:"}
                labelClassName={"textfield-content-label"}
                className={"text-field-content"}
                isInvalid={advocateInvalid}
                onChange={e => {
                  setData({
                    ...data,
                    how_to_advocate_text: e.target.value,
                  });
                  submittedOnce
                    ? setAdvocateInvalid(
                        !validateTextField(replace(e.target.value))
                          ? true
                          : false
                      )
                    : setAdvocateInvalid(false);
                }}
                message={MESSAGE.ADVOCATE_INVALID}
              />

              <TextField
                label={"How to explain it to my child:"}
                labelClassName={"textfield-content-label"}
                className={"text-field-content"}
                isNotNeccessary = {true}
                onChange={e => {
                  setData({
                    ...data,
                    explain_to_child: e.target.value,
                  });
                }}
              />
            </Col>

            <div className="horizontal-line"></div>

            <Col>
              <UploadMedia
                data={data}
                setData={setData}
                handleReelDelete={handleReelDelete}
                handleImageDelete={handleImageDelete}
                media={media}
                setMedia={setMedia}
                loader={loader}
                reelLoader={reelLoader}
                setLoader={setLoader}
                setReelLoader={setReelLoader}
              />
              <div style={{ marginTop: "-6px" }}>
                <DateField
                  show={show}
                  selectedDate={selectedDate}
                  setShow={e => setShow(e.target.checked)}
                  setSelectedDate={date => {
                    setSelectedDate(date);
                  }}
                />
              </div>
              <ContentButton
                title={"Add Procedure"}
                onClick={handleAdd}
                className={"procedure-button"}
              />
            </Col>
          </Row>
        </Form>
        <Footer className={"content-management-footer"} />
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  try {
    return {
      user: state.user.profile,
      details: state.userManagementDetails.details,
      loader: state.loader.mainLoader,
      responseSuccess: state.responseSuccess.responseSuccess,
      fullHospitals: state.fullHospitals,
      userRole: state.user.profile.role,
      hospitalDetails: state.hospitalDetails,
    };
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reset: actionCreator.reset,
      publishQuote: actionCreator.publishQuote,
      toggleMainLoader: actionCreator.toggleMainLoader,
      addProcedure: actionCreator.addProcedure,
      getFullHospitals: actionCreator.getFullHospitals,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProcedures);
