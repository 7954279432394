import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { RED_DELETE_ICON } from "../../constant";
import { S3Client } from "../../utils/upload";
import Resizer from "react-image-file-resizer";
import { validateVideo } from "../../utils/validations";
import imageCompression from 'browser-image-compression';

//This component is for handling audio,singleImage and multipleImage upload
const ImageUpload = (props) => {
  const {
    title,
    data,
    setData,
    handleImageDelete,
    handleMultiImageDelete,
    optional,
    loading,
    setLoading,
  } = props;


  const handleAudio = (e) => {
    setLoading(true);
    S3Client.uploadFile(e.target.files[0])
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  // const resizeFile = (file) =>
  //   new Promise((resolve) => {
  //     Resizer.imageFileResizer(
  //       file,
  //       800,
  //       800,
  //       "JPEG",
  //       100,
  //       0,
  //       (uri) => {
  //         resolve(uri);
  //       },
  //       "file"
  //     );
  //   });

  const resizeFile = (file) => new Promise(async (resolve, reject) => {
    // Options for compression
    const options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 2000,
      useWebWorker: true,
    };
  
    try {
      // Wait for the compression to complete
      const compressedFile = await imageCompression(file, options);
  
      if (!compressedFile) {
        // If compressedFile is undefined, reject the promise
        return reject(new Error('Failed to compress the image.'));
      }
  
      // Resolve with the compressed file
      resolve(compressedFile);
    } catch (error) {
      console.error('Error during compression:', error);
      reject(error);
    }
  });

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    setLoading(true);

    try {
      let fileToUpload;
      const fileType = file.type;

      if (fileType.startsWith("image/")) {
        fileToUpload = await resizeFile(file);

        S3Client.uploadFile(fileToUpload)
          .then((res) => {
            setData(res);
            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      } else if (fileType.startsWith("video/")) {
        fileToUpload = file;

        S3Client.uploadFile(fileToUpload)
          .then((res) => {
            setData(res);
            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      } else {
        throw new Error("Unsupported file type");
      }

      const res = await S3Client.uploadFile(fileToUpload);
      setData(res);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form.Label className="modal-content-label">
        {title}
        {!optional ? <span style={{ color: "red" }}> *</span> : null}
      </Form.Label>

      <label htmlFor="upload-button">
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {data.audio ? (
            <>
              <audio controls style={{ width: "250px" }}>
                <source src={data.audio} type="audio/mpeg" />
                <p>
                  Your browser doesn't support HTML5 audio. Here is a{" "}
                  <a href={data.audio}>link to the audio</a> instead.
                </p>
              </audio>
            </>
          ) : (
            <>
              {" "}
              <div className="glossary-image-wrapper">
                {loading && (
                  <Spinner className="spinner-class" animation="border" />
                )}
              </div>
            </>
          )}

          {data.images ? (
            data.images.map((item, index) => {
              return (
                <div key={index}>
                  <>
                    <img src={item} alt="" className="glossary-image-wrapper" />
                    <div>
                      <button
                        className="glossary-hospital-icon"
                        onClick={(e) => handleMultiImageDelete(e, index)}
                      >
                        <img
                          src={RED_DELETE_ICON}
                          alt=""
                          style={{
                            paddingBottom: "3px",
                            paddingRight: "3px",
                          }}
                        />
                      </button>
                    </div>
                  </>
                </div>
              );
            })
          ) : data.image ? (
            <div>
              <>
                {/* <img
                  src={data.image}
                  alt=""
                  className="glossary-image-wrapper"
                /> */}

                {validateVideo(data.image) ? (
                  <video
                    className="glossary-image-wrapper"
                    width="120"
                    height="120"
                    controls
                  >
                    <source src={data.image} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    src={data.image}
                    alt=""
                    className="glossary-image-wrapper"
                  />
                )}

                <div>
                  <button
                    className="glossary-hospital-icon"
                    onClick={handleImageDelete}
                  >
                    <img
                      src={RED_DELETE_ICON}
                      alt=""
                      style={{
                        paddingBottom: "3px",
                        paddingRight: "3px",
                      }}
                    />
                  </button>
                </div>
              </>
            </div>
          ) : data.url ? (
            <>
              <div style={{ marginTop: "45px" }}>
                <a href={data.url} download>
                  Published Pdf
                </a>
              </div>
            </>
          ) : null}
        </div>
      </label>
      {title === "Upload File" ? (
        <input
          type="file"
          id="upload-button"
          style={{ display: "none" }}
          onChange={handleAudio}
          accept={data.audio ? "audio/*" : "application/pdf"}
        />
      ) : (
        <input
          type="file"
          id="upload-button"
          style={{ display: "none" }}
          onChange={handleImageUpload}
          accept={"image/*"}
        />
      )}
    </>
  );
};
export default ImageUpload;
