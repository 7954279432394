import React from 'react';
import { BUTTON_SPINNER } from "../../constant"

const SaveButton = (props) => {
    return (
        <>
            <button className={props.className} onClick={props.submit}>
                {!props.loader ? <>{props.buttonName}</> : <img src={BUTTON_SPINNER} style={{ height: "30px" }} alt="" />}
            </button></>
    )
}

export default SaveButton;
