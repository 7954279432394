import React, { useState, useEffect } from "react";
import AddModalHoc from "../AddModalHoc/addModalHoc";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import { Col, Form, Row } from "react-bootstrap";
import { MESSAGE } from "../../../constant";
import moment from "moment";
import { validateName } from "../../../utils/validations";
import TextField from "../textField";
import { replace, status } from "../../../utils/rename";
import DateField from "../dateField";
import ContentButton from "../contentButton";
import ImageUpload from "../ImageUpload";
import MultiSelect from "../../UserManagement/multiSelect";
import { USER_GENDER_DROPDOWN } from "../../../constant";
import { rename } from "../../../utils/rename";
import { components } from "react-select";
import { clone } from "lodash";

const MenuList = props => {
  return (
    <components.MenuList {...props}>
      <div className="select-style">
        You can select multiple options from the list of hospitals{" "}
      </div>
      {props.children}
    </components.MenuList>
  );
};

const Option = props => {
  return (
    <components.Option {...props}>
      <div>
        {props.children}
        <input
          className="option-custom-input"
          type="checkbox"
          checked={props.isSelected}
          onChange={e => null}
        ></input>
      </div>
    </components.Option>
  );
};

const AddWhatsGoingOn = props => {
  const [termInvalid, setTermInvalid] = useState(false);
  const [meanInvalid, setMeanInvalid] = useState(false);
  const [whatToDoInvalid, setWhatToDoInvalid] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState();
  const [hospitalInvalid, setHospitalInvalid] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);

  const [data, setData] = useState({
    term: "",
    what_it_mean: "",
    what_to_do: "",
    images: [],
    hospital: [],
    hospitalList: [],
  });

  useEffect(() => {
    if (props.isEdit) {
      let hospitalArray = [];
      let hospitalIdArray = [];

      if (
        props.whatsGoingOnMe.hospital &&
        props.whatsGoingOnMe.hospital.length > 0 &&
        options?.length > 0
      ) {

        props.whatsGoingOnMe.hospital &&
          props.whatsGoingOnMe.hospital.forEach(hospital => {
            options.map(option => {

              if (option.value === parseInt(hospital)) {
                hospitalArray.push(option);
                hospitalIdArray.push(option.value);
              }
            });
          });
        }
      setData({
        term: props.whatsGoingOnMe.cellOne,
        what_it_mean: props.whatsGoingOnMe.what_it_mean
          ? props.whatsGoingOnMe.what_it_mean
          : "",
        what_to_do: props.whatsGoingOnMe.what_to_do
          ? props.whatsGoingOnMe.what_to_do
          : "",
        images: props.whatsGoingOnMe.pictures
          ? props.whatsGoingOnMe.pictures
          : [],
          hospitalList:
          hospitalArray.length === options?.length - 1
            ? [...hospitalArray, { label: "All", value: "all" }]
            : hospitalArray,
        hospital: hospitalIdArray,
      });
    }else{

      setData({
        ...data,
        hospital : props.userRole.includes("ADMIN") ? data.hospital : [props.hospitalDetails.id]
      })
    }
  }, [options]);



  useEffect(() => {
    props.getFullHospitals();
  }, []);

  useEffect(() => {

    setOptions(
      [
        ...rename(props.fullHospitals.data, "value", "label", "id", "name"),
        { label: "All", value: "all" },
      ].sort((a, b) =>
        (a["label"] || "")
          .toString()
          .localeCompare((b["label"] || "").toString())
      )
    );
  }, [props.fullHospitals.data]);

  const handleImageDelete = (e, index) => {
    e.preventDefault();
    const arr = [...data.images];
    arr.splice(index, 1);
    setData({ ...data, images: arr });
  };

  const isIncludingString = (string, option) => {
    let result = false;
    if (
      !string ||
      option.label.toString().includes(string) ||
      option.value.toString().includes(string)
    ) {
      result = true;
    }
    return result;
  };
  
  const setHospital = (opt, { option }) => {
    let newOpts = opt;
    let string = newOpts.value;
    let hospitalArray = [];
    let hospitalDetailsArray = [];
    if (data.hospital?.length === 0) {
      data.hospitalList &&
        data.hospitalList.map(hopital => {
          hospitalArray.push(Number(hopital.id));
          hospitalDetailsArray.push(hopital);
        });
    }
    if (
      data.hospitalList?.pop()?.label === "All" &&
      option &&
      option.value === "all"
    ) {
      hospitalArray = [];
      hospitalDetailsArray = [];
    } else if (option && option.value === "all") {
      let filteredOptions = clone(options);
      filteredOptions = filteredOptions.filter(
        filteredOption =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption)
      );
      newOpts = newOpts
        .concat(filteredOptions)
        .filter(newOpt => newOpt.value !== "all");
      newOpts.map(val => {
        hospitalArray.push(Number(val.value));
        hospitalDetailsArray.push(val);
      });
    } else {
      opt.map(obj => {
        hospitalArray.push(Number(obj.value));
        hospitalDetailsArray.push(obj);
      });
    }
    setHospitalInvalid(false);
    const is_same = () => {
      let count = 0;
      hospitalDetailsArray.map(obj => {
        if (options.includes(obj)) {
          count = count + 1;
        }
      });
      return count;
    };
    if (is_same() === options.length - 1) {
      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [
          ...new Set([...hospitalDetailsArray, { label: "All", value: "all" }]),
        ],
      });
    } else {
      hospitalDetailsArray = hospitalDetailsArray.filter(
        item => item.value !== "all"
      );

      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [...new Set(hospitalDetailsArray)],
      });
    }
  };

  //function to submit the data
  const handleSubmit = e => {
    e.preventDefault();
    props.toggleMainLoader(true);
    let validTerm = validateName(data.term);
    let validatedHospital = data.hospital.length > 0;
    if (!data.term || !validTerm) {
      setTermInvalid(true);
    }
    if (!data.what_it_mean) {
      setMeanInvalid(true);
    }
    if (!data.what_to_do) {
      setWhatToDoInvalid(true);
    }
    if (!validatedHospital) {
      setHospitalInvalid(true);
    }
    if (!data.term || !validTerm || !data.what_it_mean || !data.what_to_do || 
      !validatedHospital) {
      props.toggleMainLoader(false);
      return;
    }
    if (
      data.term &&
      validTerm &&
      data.what_it_mean &&
      data.what_to_do &&
      !termInvalid
    ) {
      const term_data = {
        user: props.user.idToken,
        term: data.term,
        what_it_mean: data.what_it_mean,
        what_to_do: data.what_to_do,
        pictures: data.images ? data.images : null,
        scheduled_date: moment(selectedDate).format("YYYY-MM-DD"),
        status: status(selectedDate),
        hospital: data.hospital,
      };
      props.addTerm(term_data);
    }
  };

  //function to update the data
  const handleUpdate = e => {
    e.preventDefault();
    props.toggleMainLoader(true);
    let validTerm = validateName(data.term);
    let validatedHospital = data.hospital.length > 0;
    if (!data.term || !validTerm) {
      setTermInvalid(true);
    }
    if (!data.what_it_mean) {
      setMeanInvalid(true);
    }
    if (!data.what_to_do) {
      setWhatToDoInvalid(true);
    }
    if (!validatedHospital) {
      setHospitalInvalid(true);
    }
    if (!data.term || !validTerm || !data.what_it_mean || !data.what_to_do || 
      !validatedHospital) {
      props.toggleMainLoader(false);
      return;
    }
    if (
      data.term &&
      validTerm &&
      data.what_it_mean &&
      data.what_to_do &&
      !termInvalid
    ) {
      const term_data = {
        user: props.whatsGoingOnMe.user_id,
        term: data.term,
        what_it_mean: data.what_it_mean,
        what_to_do: data.what_to_do,
        pictures: data.images ? data.images : null,
        hospital: data.hospital,
      };
      props.updateWhatsGoingOnMe(props.whatsGoingOnMe.id, term_data);
    }
  };

  const styles = {
    control: (base, state) => ({
      ...base,
      border: submittedOnce
        ? data.hospital.length === 0
          ? "1px solid #eb5757"
          : 0
        : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(0 123 255 / 25%)" : 0,
      "&:hover": {
        border: submittedOnce
          ? data.hospital.length === 0
            ? "1px solid #eb5757"
            : 0
          : 0,
      },
    }),
    valueContainer: (base, state) => ({
      ...base,
      width: "100%",
      maxWidth: "552px",
      height: "60px",
      backgroundColor: "#f4f4f4",
      borderRadius: "8px",
      boxSizing: "border-box",
      border: "none",
      backgroundImage: `url(${USER_GENDER_DROPDOWN})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "95% 50%",
      marginTop: "2px",
    }),

    indicatorsContainer: (base, state) => ({
      ...base,
      display: "none",

      backgroundColor: "#f4f4f4",

      border: "none",
      backgroundImage: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#5b616b",
      outline: "none",
    }),
    option: (base, state) => ({
      ...base,
      borderBottom: "1px solid #AEB0B5",
      color: "#455674",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      paddingTop: "16px",
      paddingBottom: "16px",
      backgroundColor: "none",
      paddingLeft: "20px",
    }),
  };



  return (
    <>
      <Form noValidate>
      {props.userRole.includes("ADMIN") ?
          <Row style={{ marginTop: "24px" }}>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                Hospital<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <MultiSelect
                multi={true}
                placeholder={"Select Hospital"}
                options={options}
                styles={styles}
                components={{ MenuList, Option }}
                className="select-hospital-class"
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                onChange={setHospital}
                value={data.hospitalList}
              />
              {submittedOnce ? (
                hospitalInvalid ? (
                  <p className="clinician-invalid">Please choose a category</p>
                ) : null
              ) : null}
            </Form.Group>
          </Col>
        </Row> : 
        
        <Row style={{ marginTop: "24px" }}>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                Hospital<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <MultiSelect
                multi={true}
                placeholder={"Select Hospital"}
                options={options}
                styles={styles}
                className="select-hospital-class"
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                value={[{"label":props.hospitalDetails.name, "value":props.hospitalDetails.name}]}
                disabled = {true}
              />
              {submittedOnce ? (
                hospitalInvalid ? (
                  <p className="clinician-invalid">Please choose a category</p>
                ) : null
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        }

        <Row style={{ marginTop: "24px" }}>
          <Col style={{ paddingRight: "10px" }}>
            <Form.Group controlId="formGroupEmail">
              <Form.Label className="modal-content-label">
                Term Title <span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Type Here"
                className="input-field-content"
                isInvalid={termInvalid}
                value={data.term}
                onChange={e => {
                  setData({ ...data, term: e.target.value });
                  setTermInvalid(!validateName(e.target.value));
                }}
              />
              <Form.Control.Feedback
                type="invalid"
                className="clinician-invalid"
              >
                {MESSAGE.TITLE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <TextField
          label={"What it means"}
          className={"text-field-content"}
          labelClassName={"textfield-content-label-glossary"}
          placeholder={"Type Here"}
          isInvalid={meanInvalid}
          value={data.what_it_mean}
          onChange={e => {
            setData({ ...data, what_it_mean: e.target.value });
            setMeanInvalid(false);
          }}
          message={MESSAGE.EMPTY_FIELD}
        />

        <TextField
          label={"What to do"}
          className={"text-field-content"}
          labelClassName={"textfield-content-label-glossary"}
          placeholder={"Type Here"}
          isInvalid={whatToDoInvalid}
          value={data.what_to_do}
          onChange={e => {
            setData({ ...data, what_to_do: e.target.value });
            setWhatToDoInvalid(false);
          }}
          message={MESSAGE.EMPTY_FIELD}
        />

        <ImageUpload
          title={"Upload Pictures"}
          optional={false}
          data={data}
          setData={res => {
            setData({ ...data, images: [...data.images, res.location] });
          }}
          handleMultiImageDelete={handleImageDelete}
          loading={loading}
          setLoading={setLoading}
        />
        {submittedOnce ? (
          !data.images ? (
            <p className="clinician-invalid">Please upload valid image</p>
          ) : null
        ) : (
          <Form.Text className="text-muted" style={{ marginTop: "-10px" }}>
            **Please Add JPG or PNG. Pictures should be aligned vertically. If posted horizontal, pictures will rotate when exported.
          </Form.Text>
        )}
        <Row>
          <Col>
            {!props.isEdit ? (
              <DateField
                show={show}
                selectedDate={selectedDate}
                setShow={e => setShow(e.target.checked)}
                setSelectedDate={date => {
                  setSelectedDate(date);
                }}
              />
            ) : null}
          </Col>

          <Col className="mt-auto">
            <ContentButton
              onClick={props.isEdit ? handleUpdate : handleSubmit}
              title={props.isEdit ? "Update Term" : "Publish Term"}
              className={"podcast-button"}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

const mapStateToProps = state => {
  try {
    return {
      user: state.user.profile,
      loader: state.loader.mainLoader,
      whatsGoingOnMe: state.contentDetails,
      userRole: state.user.profile.role,
      hospitalDetails: state.hospitalDetails,
      fullHospitals: state.fullHospitals,
    };
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      addTerm: actionCreator.addWhatsGoingOnMe,
      toggleMainLoader: actionCreator.toggleMainLoader,
      updateWhatsGoingOnMe: actionCreator.updateWhatsgoingOnMe,
      getFullHospitals: actionCreator.getFullHospitals,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddModalHoc(AddWhatsGoingOn));
