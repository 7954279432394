import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { MESSAGE, BUTTON_SPINNER } from "../../../constant";
import "./dailyQuotes.css";
import "../../UserManagement/UserManagementClinicianDetails/userManagementClinicianDetails.css";
import AddModalHoc from "../AddModalHoc/addModalHoc";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import DatePicker from "react-datepicker";
import moment from "moment";
import { validateName, validateTextField } from "../../../utils/validations";
import { status } from "../../../utils/rename";
import MultiSelect from "../../UserManagement/multiSelect";
import { USER_GENDER_DROPDOWN } from "../../../constant";
import { rename } from "../../../utils/rename";
import { components } from "react-select";
import { clone } from "lodash";

const MenuList = props => {
  return (
    <components.MenuList {...props}>
      <div className="select-style">
        You can select multiple options from the list of hospitals{" "}
      </div>
      {props.children}
    </components.MenuList>
  );
};

const Option = props => {
  return (
    <components.Option {...props}>
      <div>
        {props.children}
        <input
          className="option-custom-input"
          type="checkbox"
          checked={props.isSelected}
          onChange={e => null}
        ></input>
      </div>
    </components.Option>
  );
};

const AddDailyQuotes = props => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [authorInvalid, setAuthorInvalid] = useState(false);
  const [quoteInvalid, setQuoteInvalid] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [options, setOptions] = useState();
  const [hospitalInvalid, setHospitalInvalid] = useState(false);
  const [data, setData] = useState({
    quote: "",
    author_name: "",
    hospital: [],
    hospitalList: [],
  });

  useEffect(() => {
    if (props.editQuote) {
      let hospitalArray = [];
      let hospitalIdArray = [];
      if (
        props.quotes.hospital_detail &&
        props.quotes.hospital_detail.length > 0 &&
        options?.length > 0
        
      ) {
        
        props.quotes.hospital_detail &&
          props.quotes.hospital_detail.forEach(hospital => {
            options.map(option => {
              if (option.value === hospital.id) {
                hospitalArray.push(option);
                hospitalIdArray.push(option.value);
              }
            });
          });
        }
      setData({
        quote: props.quotes.cellOne,
        author_name: props.quotes.author_name,
        hospitalList:
          hospitalArray.length === options?.length - 1
            ? [...hospitalArray, { label: "All", value: "all" }]
            : hospitalArray,
        hospital: hospitalIdArray,
      });
      setSelectedDate(moment(props.quotes.cellTwo).toDate());
    }else{
      setData({
        ...data,
        hospital : props.userRole.includes("ADMIN") ? data.hospital : [props.hospitalDetails.id]
      })
    }
  }, [options]);

  
  useEffect(() => {
    props.getFullHospitals();
  }, []);

  useEffect(() => {
    setOptions(
      [
        ...rename(props.fullHospitals.data, "value", "label", "id", "name"),
        { label: "All", value: "all" },
      ].sort((a, b) =>
        (a["label"] || "")
          .toString()
          .localeCompare((b["label"] || "").toString())
      )
    );
  }, [props.fullHospitals.data]);

  const isIncludingString = (string, option) => {
    let result = false;
    if (
      !string ||
      option.label.toString().includes(string) ||
      option.value.toString().includes(string)
    ) {
      result = true;
    }
    return result;
  };
  
  const setHospital = (opt, { option }) => {
    let newOpts = opt;
    let string = newOpts.value;
    let hospitalArray = [];
    let hospitalDetailsArray = [];
    if (data.hospital?.length === 0) {
      data.hospitalList &&
        data.hospitalList.map(hopital => {
          hospitalArray.push(Number(hopital.id));
          hospitalDetailsArray.push(hopital);
        });
    }
    if (
      data.hospitalList?.pop()?.label === "All" &&
      option &&
      option.value === "all"
    ) {
      hospitalArray = [];
      hospitalDetailsArray = [];
    } else if (option && option.value === "all") {
      let filteredOptions = clone(options);
      filteredOptions = filteredOptions.filter(
        filteredOption =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption)
      );
      newOpts = newOpts
        .concat(filteredOptions)
        .filter(newOpt => newOpt.value !== "all");
      newOpts.map(val => {
        hospitalArray.push(Number(val.value));
        hospitalDetailsArray.push(val);
      });
    } else {
      opt.map(obj => {
        hospitalArray.push(Number(obj.value));
        hospitalDetailsArray.push(obj);
      });
    }
    setHospitalInvalid(false);
    const is_same = () => {
      let count = 0;
      hospitalDetailsArray.map(obj => {
        if (options.includes(obj)) {
          count = count + 1;
        }
      });
      return count;
    };
    if (is_same() === options.length - 1) {
      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [
          ...new Set([...hospitalDetailsArray, { label: "All", value: "all" }]),
        ],
      });
    } else {
      hospitalDetailsArray = hospitalDetailsArray.filter(
        item => item.value !== "all"
      );

      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [...new Set(hospitalDetailsArray)],
      });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmittedOnce(true);
    let validatedAuthor = validateName(data.author_name);
    let validatedQuote = validateTextField(data.quote);
    let validatedHospital = data.hospital && data.hospital.length > 0;
    if (!validatedAuthor) {
      setAuthorInvalid(true);
    }
    if (!validatedQuote) {
      setQuoteInvalid(true);
    }
    if (!validatedHospital) {
      setHospitalInvalid(true);
    }
    if (!validatedQuote || !validatedAuthor || !selectedDate || !validatedHospital) {
      return;
    }
    props.toggleMainLoader(true);
    props.publishQuote({
      user: props.user.idToken,
      quote: data.quote,
      scheduled_date: selectedDate
        ? moment(selectedDate).format("YYYY-MM-DD")
        : "",
      status: status(selectedDate),
      author_name: data.author_name ? data.author_name : null,
      hospital: data.hospital,
    });

  };

  const handleUpdate = e => {
    e.preventDefault();
    setSubmittedOnce(true);
    let validatedAuthor = validateName(data.author_name);
    let validatedQuote = validateTextField(data.quote);
    let validatedHospital = data.hospital.length > 0;
    if (!validatedAuthor) {
      setAuthorInvalid(true);
    }
    if (!validatedQuote) {
      setQuoteInvalid(true);
    }
    if (!validatedHospital) {
      setHospitalInvalid(true);
    }
    if (!validatedQuote || !validatedAuthor || !selectedDate || !validatedHospital) {
      return;
    }

    props.toggleMainLoader(true);
    props.updateDailyQuotes(props.quotes.id, {
      user: props.user.idToken,
      quote: data.quote,
      scheduled_date: selectedDate
        ? moment(selectedDate).format("YYYY-MM-DD")
        : "",
      status: status(selectedDate),
      author_name: data.author_name ? data.author_name : null,
      hospital: data.hospital,
    });
  };

  const styles = {
    control: (base, state) => ({
      ...base,
      border: submittedOnce
        ? data.hospital && data.hospital.length === 0
          ? "1px solid #eb5757"
          : 0
        : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(0 123 255 / 25%)" : 0,
      "&:hover": {
        border: submittedOnce
          ? data.hospital && data.hospital.length === 0
            ? "1px solid #eb5757"
            : 0
          : 0,
      },
    }),
    valueContainer: (base, state) => ({
      ...base,
      width: "100%",
      maxWidth: "552px",
      height: "60px",
      backgroundColor: "#f4f4f4",
      borderRadius: "8px",
      boxSizing: "border-box",
      border: "none",
      backgroundImage: `url(${USER_GENDER_DROPDOWN})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "95% 50%",
      marginTop: "2px",
    }),

    indicatorsContainer: (base, state) => ({
      ...base,
      display: "none",

      backgroundColor: "#f4f4f4",

      border: "none",
      backgroundImage: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#5b616b",
      outline: "none",
    }),
    option: (base, state) => ({
      ...base,
      borderBottom: "1px solid #AEB0B5",
      color: "#455674",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      paddingTop: "16px",
      paddingBottom: "16px",
      backgroundColor: "none",
      paddingLeft: "20px",
    }),
  };

  return (
    <>
      <Form noValidate>
      {props.userRole.includes("ADMIN") ?
          <Row style={{ marginTop: "24px" }}>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                Hospital<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <MultiSelect
                multi={true}
                placeholder={"Select Hospital"}
                options={options}
                styles={styles}
                components={{ MenuList, Option }}
                className="select-hospital-class"
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                onChange={setHospital}
                value={data.hospitalList}
              />
              {submittedOnce ? (
                hospitalInvalid ? (
                  <p className="clinician-invalid">Please choose a category</p>
                ) : null
              ) : null}
            </Form.Group>
          </Col>
        </Row> : 
        
        <Row style={{ marginTop: "24px" }}>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                Hospital<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <MultiSelect
                multi={true}
                placeholder={"Select Hospital"}
                options={options}
                styles={styles}
                className="select-hospital-class"
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                value={[{"label":props.hospitalDetails.name, "value":props.hospitalDetails.name}]}
                disabled = {true}
              />
              {submittedOnce ? (
                hospitalInvalid ? (
                  <p className="clinician-invalid">Please choose a category</p>
                ) : null
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        }
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label className="modal-content-label">
            Add Quote Here<span style={{ color: "red" }}> *</span>
          </Form.Label>
          <Form.Control
            className="text-field-content"
            as="textarea"
            maxLength="200"
            placeholder="Type Or Paste Your Quote"
            value={data.quote}
            onChange={e => {
              setData({ ...data, quote: e.target.value });
              submittedOnce
                ? setQuoteInvalid(
                    validateTextField(e.target.value) ? false : true
                  )
                : setQuoteInvalid(false);
            }}
            isInvalid={quoteInvalid}
          />
          <Form.Control.Feedback type="invalid" className="clinician-invalid">
            {MESSAGE.QUOTE_INVALID}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="chracter-remain">
          {" "}
          {200 - data.quote.length}/200 Characters
        </div>
        <Row style={{ marginTop: "30px" }}>
          <Col style={{ paddingRight: "10px" }}>
            <Form.Group controlId="formGroupEmail">
              <Form.Label className="modal-content-label">
                Author Name<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Author’s Name"
                className="input-field-content"
                value={data.author_name}
                onChange={e => {
                  setData({ ...data, author_name: e.target.value });
                  submittedOnce
                    ? setAuthorInvalid(!validateName(e.target.value))
                    : setAuthorInvalid(false);
                }}
                isInvalid={authorInvalid}
              />
              <Form.Control.Feedback
                type="invalid"
                className="clinician-invalid"
              >
                {MESSAGE.AUTHOR_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col style={{ paddingLeft: "10px" }}>
            <Form.Group controlId="formGroupEmail">
              <Form.Label className="modal-content-label">
                Date<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <DatePicker
                dateFormat="MM/dd/yyyy"
                placeholderText="Set Date"
                selected={selectedDate}
                minDate={new Date()}
                onChange={date => {
                  setSelectedDate(date);
                }}
                className={
                  selectedDate
                    ? "input-date-field-content"
                    : "input-date-field-content1"
                }
              />
              {!selectedDate && (
                <div className="clinician-invalid">{MESSAGE.DATE_INVALID}</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <button
          className="content-save-button"
          disabled={props.disabled}
          onClick={props.editQuote ? handleUpdate : handleSubmit}
        >
          {props.loader ? (
            <img src={BUTTON_SPINNER} alt="" height="30px" />
          ) : (
            <span>{props.editQuote ? "Update Quote" : " Publish Quote"}</span>
          )}
        </button>
      </Form>
    </>
  );
};

const mapStateToProps = state => {
  try {
    return {
      user: state.user.profile,
      details: state.userManagementDetails.details,
      loader: state.loader.mainLoader,
      quotes: state.contentDetails,
      userRole: state.user.profile.role,
      hospitalDetails: state.hospitalDetails,
      fullHospitals: state.fullHospitals,
    };
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reset: actionCreator.reset,
      publishQuote: actionCreator.publishQuote,
      toggleMainLoader: actionCreator.toggleMainLoader,
      updateDailyQuotes: actionCreator.updateDailyQuotes,
      getFullHospitals: actionCreator.getFullHospitals,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddModalHoc(AddDailyQuotes));
