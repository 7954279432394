import React, { useState, useEffect } from "react";
import UserManagementCommonTable from "../../commonComponent/userManagementCommonTable";
import { Container } from "react-bootstrap";
import Footer from "../../Footer";
import NavBar from "../../NavBar";
import Back from "../../../common/components/back";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import UserFilter from "../UserManagementFilter/userManagementFilter";

import { ROUTES_PATH } from "../../../constant";
import { getAllParentChildListService, getAllClsListService, getAllClinicianListService } from "../../../modules/services";
import { toast } from "react-toastify";


const UserManagementDashboard = props => {
  const [search, setSearch] = useState("");
  const [dataList, setDataList] = useState();
  const [parentChild, setParentChild] = useState(false);
  const [cls, setCls] = useState(false);
  const [clinician, setClinician] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [headerState, setHeaderState] = useState("");
  const [itemCountFromServer, setItemCountFromServer] = useState(0);
  const [show, setShow] = useState(false);
  const [superUser, setSuperUser] = useState(false);
  const [dateAdded, setDateAdded] = useState(false);
  const [hospital, setHospital] = useState([]);
  const [csvLoader, setCsvLoader] = useState(false);

  useEffect(() => {
    props.toggleMainLoader(true);
    props.reset();
    props.getAllParentChild(1);
    handleClickParentChild();
    props.getAllCls(1);
    props.getAllClinician(1);
    setHeaderState("Parent Name");
    setPageNumber(1);
  }, [props.match.params.path]);

  useEffect(() => {
    if (!search && !props.responseSuccess) {
      if (clinician) {
        setDataList(props.ClinicianList.results);
      }
      if (cls) {
        setDataList(props.ClsList.results);
      }
      if (parentChild) {
        setDataList(props.ParentChildList.results);
        try {
          setItemCountFromServer(props.ParentChildList.count);
        } catch (e) {}
      }
    }
  }, [
    props.ClinicianList.results,
    props.ClsList.results,
    props.ParentChildList.results,
    pageNumber,
  ]);

  useEffect(() => {
    if (search) {
      if (props.searchData) {
        setItemCountFromServer(props.searchData.count);
        setDataList(props.searchData.results);
        props.toggleMainLoader(false);
      }
    } else {
      if (clinician) {
        handleClickClinician();
      } else if (cls) {
        handleClickCls();
      } else if (parentChild) {
        handleClickParentChild();
      }
    }
  }, [props.searchData]);

  useEffect(() => {
    if (props.responseSuccess) {
      if (props.filterData) {
        setSearch("");
        setItemCountFromServer(props.filterData.count);
        setDataList(props.filterData.results);
        if (show) {
          setPageNumber(1);
        }
        setShow(false);
      }
    }
  }, [props.filterData, props.responseSuccess]);

  const handleFilter = e => {
    e.preventDefault();
    props.toggleMainLoader(true);
    props.filterRequest(1, {
      role: cls ? "cls" : clinician ? "clinician" : "parent",
      Hospital: hospital,
      SuperUser: superUser ? "True" : "False",
      DateAdded: dateAdded ? "True" : "False",
    });
  };

  const handleClickParentChild = () => {
    localStorage.setItem("navData", "parent")
    setParentChild(true);
    setClinician(false);
    setCls(false);
    props.responseSuccessful(false);
    setSearch("");
    props.getAllParentChild(1);
    try {
      if (props.ParentChildList) {
        setDataList(props.ParentChildList.results);
        setItemCountFromServer(props.ParentChildList.count);
      }

      setPageNumber(1);
      setHeaderState("Parent Name");
    } catch (e) {}
  };

  const handleClickClinician = () => {
    localStorage.setItem("navData", "clinician")
    setClinician(true);
    setParentChild(false);
    setCls(false);
    props.responseSuccessful(false);
    setSearch("");
    props.getAllClinician(1);
    try {
      if (props.ClinicianList) {
        setItemCountFromServer(props.ClinicianList.count);
        setDataList(props.ClinicianList.results);
      }
      setPageNumber(1);
      setHeaderState("Clinician Name");
    } catch (e) {}
  };

  const handleClickCls = () => {
    localStorage.setItem("navData", "cls")
    setCls(true);
    setParentChild(false);
    setClinician(false);
    props.responseSuccessful(false);
    setSearch("");
    props.getAllCls(1);
    try {
      if (props.ClsList) {
        setItemCountFromServer(props.ClsList.count);
        setDataList(props.ClsList.results);
      }

      setPageNumber(1);
      setHeaderState("Cls Name");
    } catch (e) {}
  };

  const changePage = selected => {
    setPageNumber(selected);

    if (!search && !props.responseSuccess) {
      if (clinician) {
        props.toggleMainLoader(true);
        props.getAllClinician(selected);
      }
      if (cls) {
        props.toggleMainLoader(true);
        props.getAllCls(selected);
      }
      if (parentChild) {
        props.toggleMainLoader(true);
        props.getAllParentChild(selected);
      }
    } else if (search) {
      props.toggleMainLoader(true);
      props.searchRequest(selected, search, {
        role: parentChild
          ? "parent"
          : cls
          ? "all_cls"
          : clinician
          ? "clinician"
          : null,
      });
    } else {
      props.toggleMainLoader(true);
      props.filterRequest(selected, {
        role: cls ? "cls" : clinician ? "clinician" : "parent",
        Hospital: hospital,
        SuperUser: superUser ? "True" : "False",
        DateAdded: dateAdded ? "True" : "False",
      });
    }
  };

  const rowPerPage = 10;

  let header = {
    header1: headerState,
    header2: "Email",
  };

  const searchOnChange = e => {
    setSearch(e.target.value);
    if (e.target.value.length < 1) {
      if (clinician) {
        handleClickClinician();
      } else if (cls) {
        handleClickCls();
      } else if (parentChild) {
        handleClickParentChild();
      }
    } else {
      props.toggleMainLoader(true);
      props.searchRequest(1, e.target.value, {
        role: parentChild
          ? "parent"
          : cls
          ? "all_cls"
          : clinician
          ? "clinician"
          : null,
      });
    }
  };

  const handleViewClick = id => {
    if (clinician) {
      props.storeUserManagementDetails(
        dataList.filter(data => data.id === id)[0]
      );
      props.history.push(ROUTES_PATH.CLINICIAN_DETAILS);
    }
    if (cls) {
      props.storeUserManagementDetails(
        dataList.filter(data => data.id === id)[0]
      );
      props.history.push(ROUTES_PATH.CLS_DETAILS);
    }
    if (parentChild) {
      props.storeUserManagementDetails(
        dataList.filter(data => data.id === id)[0]
      );
      if (dataList.filter(data => data.id === id)[0].role) {
        if (dataList.filter(data => data.id === id)[0].role[0] === "PARENT") {
          props.history.push(ROUTES_PATH.PARENT_CHILD_DETAILS);
        } else {
          props.history.push(ROUTES_PATH.CHILD_DETAILS);
        }
      }
    }
  };

  const handleBack = () => {
    props.history.push(ROUTES_PATH.HOME);
  };
  const handleFilterClick = () => {
    setShow(true);
  };
  const handleCancelClose = e => {
    e.preventDefault();
    setShow(false);
  };

  const mapping = array => {
    let newArray = array.map(obj => obj.value);
    return newArray;
  };

  const handleDownloadCsv = () => {
    setCsvLoader(true);

    if(parentChild){
      getAllParentChildListService(1, true)
      .then(response =>{
        if (response.status === 200 || response.status === 201) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `parent_child_users.csv`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setCsvLoader(false)
          toast.success("Successfully Downloaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(err=> {
        console.error(err)
        setCsvLoader(false)
        toast.error("Download Failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
    }
    else if(cls){
      getAllClsListService(1, true)
      .then(response =>{
        if (response.status === 200 || response.status === 201) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `cls_users.csv`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setCsvLoader(false)
          toast.success("Successfully Downloaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(err=> {
        console.error(err)
        setCsvLoader(false)
        toast.error("Download Failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
    }
    else{
      getAllClinicianListService(1, true)
      .then(response =>{
        if (response.status === 200 || response.status === 201) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `clinician_users.csv`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setCsvLoader(false)
          toast.success("Successfully Downloaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(err=> {
        console.error(err)
        setCsvLoader(false)
        toast.error("Download Failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
    }
}

  return (
    <>
      <Container
        style={{
          minHeight: "100vh",
          position: "relative",
          maxWidth: "1046px",
        }}
      >
        <Back
          Back={handleBack}
          backIconClassName={"parent-back-icon"}
          backNavItemClassName={"parent-back-nav"}
          className="parent-back"
        />

        <NavBar
          id={"parent-navbar"}
          title={"User Management"}
          isSearch={true}
          isFilter={props.userRole.includes("ADMIN") ? true : false }
          searchOnChange={searchOnChange}
          searchValue={search}
          handleFilterClick={handleFilterClick}
          className={"navbar-class"}
          filterId={"navbar-filter"}
          isCsvButton = {true}
          handelDownloadCsv = {handleDownloadCsv}
          csvLoader = {csvLoader}
        />
        <UserManagementCommonTable
          handleClickParentChild={handleClickParentChild}
          handleClickClinician={handleClickClinician}
          handleClickCls={handleClickCls}
          dataList={dataList}
          header={header}
          parentChild={parentChild}
          clinician={clinician}
          CLS={cls}
          rowPerPage={rowPerPage}
          pageNumber={pageNumber}
          changePage={changePage}
          itemCountFromServer={itemCountFromServer}
          handleViewClick={handleViewClick}
        />
        <Footer className={"user-management-footer"} />
        <UserFilter
          handleCancelClose={handleCancelClose}
          show={show}
          title={"Select Filters"}
          contentClassName={"modal-content-quotes"}
          setDateAdded={e => setDateAdded(e.target.value)}
          setSuperUser={e => setSuperUser(e.target.value)}
          setHospital={e => setHospital(mapping(e))}
          handleFilter={handleFilter}
          parentChild={parentChild}
        />
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  try {
    return {
      details: state.userManagementDetails.details,
      ClsList: state.ClsList,
      ClinicianList: state.ClinicianList,
      ParentChildList: state.ParentChildList,
      hospitals: state.hospitals.data[0],
      searchData: state.searchData,
      filterData: state.filterData,
      responseSuccess: state.responseSuccess.responseSuccess,
      fullHospitals: state.fullHospitals,
      userRole: state.user.profile.role,
    };
  } catch (e) {}
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllParentChild: actionCreator.getAllParentChild,
      getAllCls: actionCreator.getAllCls,
      getAllClinician: actionCreator.getAllClinician,
      getAllParentChildListResponse:
        actionCreator.getAllParentChildListResponse,
      getAllClsListResponse: actionCreator.getAllClsListResponse,
      storeUserManagementDetails: actionCreator.storeUserManagementDetails,
      reset: actionCreator.reset,
      toggleMainLoader: actionCreator.toggleMainLoader,
      searchRequest: actionCreator.searchRequest,
      responseSuccessful: actionCreator.responseSuccess,
      getFullHospitals: actionCreator.getFullHospitals,
      filterRequest: actionCreator.filterRequest,
      filterValue : actionCreator.filterValue,
      navbarValue : actionCreator.navbarValue,
      resetFilter : actionCreator.resetFilter,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementDashboard);
