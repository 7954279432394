import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Back from "../../../common/components/back";
import Footer from "../../Footer";
import {
  ROUTES_PATH,
  UPLOAD_PLUS,
  MESSAGE,
  RED_DELETE_ICON,
  USER_GENDER_DROPDOWN,
} from "../../../constant";
import TextField from "../textField";
import { S3Client } from "../../../utils/upload";
import ContentButton from "../contentButton";
import { validateName, validateMeaning } from "../../../utils/validations";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import { Spinner } from "react-bootstrap";
import { components } from "react-select";
import MultiSelect from "../../UserManagement/multiSelect";
import { rename } from "../../../utils/rename";
import { clone } from "lodash";

const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      <div className="select-style">
        You can select multiple options from the list of hospitals{" "}
      </div>
      {props.children}
    </components.MenuList>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div>
        {props.children}
        <input
          className="option-custom-input"
          type="checkbox"
          checked={props.isSelected}
          onChange={(e) => null}
        ></input>
      </div>
    </components.Option>
  );
};

const EditPictures = (props) => {
  const [termInvalid, setTermInvalid] = useState(false);
  const [meaningInvalid, setMeaningInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [hospitalInvalid, setHospitalInvalid] = useState(false);

  const [data, setData] = useState({
    term: "",
    meaning: "",
    picture: [],
    hospital: [],
    hospitalList: [],
  });

  const clearData = () => {
    setData({
      term: "",
      meaning: "",
      picture: [],
      scheduled_date: "",
      status: "",
      hospital: [],
      hospitalList: [],
    });
  };

  useEffect(() => {
    props.getFullHospitals();
  }, []);

  useEffect(() => {
    setOptions(
      [
        ...rename(props.fullHospitals.data, "value", "label", "id", "name"),
        { label: "All", value: "all" },
      ].sort((a, b) =>
        (a["label"] || "")
          .toString()
          .localeCompare((b["label"] || "").toString())
      )
    );
  }, [props.fullHospitals.data]);

  useEffect(() => {
    let hospitalArray = [];
    let hospitalIdArray = [];
    if (
      props.picture.hospital_detail &&
      props.picture.hospital_detail.length > 0 &&
      options?.length > 0
    ) {
      props.picture.hospital_detail &&
        props.picture.hospital_detail.forEach((hospital) => {
          options.map((option) => {
            if (option.value === hospital.id) {
              hospitalArray.push(option);
              hospitalIdArray.push(option.value);
            }
          });
        });
    }
    if (props.userRole.includes("ADMIN")) {
      setData({
        ...data,
        hospital: props.userRole.includes("ADMIN")
          ? data.hospital
          : [props.hospitalDetails.id],
      });
    }
    setData({
      term: props.picture.cellOne,
      meaning: props.picture.cellTwo ? props.picture.cellTwo : "",
      picture: props.picture.picture ? props.picture.picture : [],
      hospitalList:
        hospitalArray.length === options?.length - 1
          ? [...hospitalArray, { label: "All", value: "all" }]
          : hospitalArray,
      hospital: hospitalIdArray,
    });
  }, [options]);

  //useEffect to navigate screen after add success
  useEffect(() => {
    if (props.responseSuccess) {
      props.history.push(ROUTES_PATH.PICTURES);
    }
    props.response(false);
  }, [props.responseSuccess]);

  const handleBack = () => {
    props.history.push(ROUTES_PATH.PICTURES);
  };

  //function to input images
  const ImageInput = useRef(null);
  const handleUploadImage = (e) => {
    ImageInput.current.click();
  };
  const handleImageUpload = (e) => {
    setLoading(true);
    S3Client.uploadFile(e.target.files[0])
      .then((res) => {
        setData({ ...data, picture: [...data.picture, res.location] });
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleImageDelete = (e, index) => {
    const arr = [...data.picture];
    arr.splice(index, 1);
    setData({ ...data, picture: [...arr] });
  };

  const isIncludingString = (string, option) => {
    let result = false;
    if (
      !string ||
      option.label.toString().includes(string) ||
      option.value.toString().includes(string)
    ) {
      result = true;
    }
    return result;
  };

  const setHospital = (opt, { option }) => {
    let newOpts = opt;
    let string = newOpts.value;
    let hospitalArray = [];
    let hospitalDetailsArray = [];
    if (data.hospital?.length === 0) {
      data.hospitalList &&
        data.hospitalList.map((hopital) => {
          hospitalArray.push(Number(hopital.id));
          hospitalDetailsArray.push(hopital);
        });
    }
    if (
      data.hospitalList?.pop()?.label === "All" &&
      option &&
      option.value === "all"
    ) {
      hospitalArray = [];
      hospitalDetailsArray = [];
    } else if (option && option.value === "all") {
      let filteredOptions = clone(options);
      filteredOptions = filteredOptions.filter(
        (filteredOption) =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption)
      );
      newOpts = newOpts
        .concat(filteredOptions)
        .filter((newOpt) => newOpt.value !== "all");
      newOpts.map((val) => {
        hospitalArray.push(Number(val.value));
        hospitalDetailsArray.push(val);
      });
    } else {
      opt.map((obj) => {
        hospitalArray.push(Number(obj.value));
        hospitalDetailsArray.push(obj);
      });
    }
    setHospitalInvalid(false);
    const is_same = () => {
      let count = 0;
      hospitalDetailsArray.map((obj) => {
        if (options.includes(obj)) {
          count = count + 1;
        }
      });
      return count;
    };
    if (is_same() === options.length - 1) {
      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [
          ...new Set([...hospitalDetailsArray, { label: "All", value: "all" }]),
        ],
      });
    } else {
      hospitalDetailsArray = hospitalDetailsArray.filter(
        (item) => item.value !== "all"
      );

      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [...new Set(hospitalDetailsArray)],
      });
    }
  };

  //function to add pictures
  const handleUpdate = (e) => {
    e.preventDefault();
    props.toggleMainLoader(true);
    let termValid = validateName(data.term);
    let meaningValid = validateMeaning(data.meaning);
    let validatedHospital = data.hospital?.length > 0;
    if (!termValid || !data.term) {
      setTermInvalid(true);
    }
    if (!meaningValid || !data.meaning) {
      setMeaningInvalid(true);
    }
    if (!validatedHospital) {
      setHospitalInvalid(true);
    }
    if (
      !termValid ||
      !meaningValid ||
      !data.picture.length ||
      !validatedHospital
    ) {
      props.toggleMainLoader(false);
      return;
    }
    if ((termValid && meaningValid) || data.picture.length) {
      const picture_data = {
        user: props.user.idToken,
        term: data.term,
        meaning: data.meaning,
        picture: data.picture ? data.picture : null,
        hospital: data.hospital,
        // scheduled_date: moment(selectedDate).format("YYYY-MM-DD"),
        // status: status(selectedDate)
      };
      props.updatePicture(props.picture.id, picture_data);
      clearData();
    }
  };

  const styles = {
    control: (base, state) => ({
      ...base,
      border: hospitalInvalid ? "1px solid #eb5757" : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(0 123 255 / 25%)" : 0,
      "&:hover": {
        border: hospitalInvalid ? "1px solid #eb5757" : 0,
      },
    }),
    valueContainer: (base, state) => ({
      ...base,
      width: "100%",
      maxWidth: "552px",
      height: "60px",
      backgroundColor: "#f4f4f4",
      borderRadius: "8px",
      boxSizing: "border-box",
      border: "none",
      backgroundImage: `url(${USER_GENDER_DROPDOWN})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "95% 50%",
      marginTop: "2px",
    }),

    indicatorsContainer: (base, state) => ({
      ...base,
      display: "none",

      backgroundColor: "#f4f4f4",

      border: "none",
      backgroundImage: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#5b616b",
      outline: "none",
    }),
    option: (base, state) => ({
      ...base,
      borderBottom: "1px solid #AEB0B5",
      color: "#455674",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      paddingTop: "16px",
      paddingBottom: "16px",
      backgroundColor: "none",
      paddingLeft: "20px",
    }),
  };

  const deletePictures = () => {
    props.toggleMainLoader(true);
    props.deletePicture(props?.picture?.id)
    props.history.push(ROUTES_PATH.PICTURES)
  }

  return (
    <>
      <Container className="common-container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Back
            Back={handleBack}
            backIconClassName={"parent-back-icon"}
            backNavItemClassName={"parent-back-nav"}
            className="parent-back"
          />
          <button
            style={{
              backgroundColor: "transparent",
              border: "1px solid #ff6f59",
              cursor: "pointer",
              paddingTop: "8px",
              paddingBottom: "8px",
              height: "40px",
              width: "auto",
              marginTop: "30px",
              borderRadius: "5px",
              color: "#ff6f59",
              display: "flex",
              gap: "10px",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
            onClick={() => deletePictures()}
          >
            Delete
            <img src={RED_DELETE_ICON} style={{ paddingTop: "1px" }} alt="" />
          </button>
        </div>
        <Form noValidate></Form>
        <Row style={{ marginTop: "27px" }}>
          <Col>
            <p className="title-add-procedure">Update Pictures</p>
            {props.userRole.includes("ADMIN") ? (
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="parent-name">
                  Hospital<span style={{ color: "red" }}> *</span>
                </Form.Label>
                <MultiSelect
                  multi={true}
                  placeholder={"Select Hospital"}
                  options={options}
                  styles={styles}
                  components={{ MenuList, Option }}
                  className="select-hospital-class"
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  onChange={setHospital}
                  value={data.hospitalList}
                />
                {hospitalInvalid ? (
                  <p className="clinician-invalid">Please choose a category</p>
                ) : null}
              </Form.Group>
            ) : (
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="parent-name">
                  Hospital<span style={{ color: "red" }}> *</span>
                </Form.Label>
                <MultiSelect
                  multi={true}
                  placeholder={"Select Hospital"}
                  styles={styles}
                  components={{ MenuList, Option }}
                  className="select-hospital-class"
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  value={[
                    {
                      label: props.hospitalDetails.name,
                      value: props.hospitalDetails.name,
                    },
                  ]}
                  disabled={true}
                />
                {hospitalInvalid ? (
                  <p className="clinician-invalid">Please choose a category</p>
                ) : null}
              </Form.Group>
            )}
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="procedure-content-label">
                Picture Title<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                className="input-field-content"
                type="text"
                placeholder="Enter Title Here"
                value={data.term}
                isInvalid={termInvalid}
                maxLength={100}
                onChange={(e) => {
                  setData({ ...data, term: e.target.value });
                  setTermInvalid(!validateName(e.target.value));
                }}
              />

              <div style={{ marginBottom: "10px" }}>
                <div className="chracter-remain-title">
                  {100 - data.term.length}/100 Characters{" "}
                </div>
              </div>

              <Form.Control.Feedback
                className="clinician-invalid"
                type="invalid"
              >
                {MESSAGE.TITLE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>

            <TextField
              label={"Description"}
              labelClassName={"textfield-content-label"}
              className={"text-field-content"}
              isInvalid={meaningInvalid}
              value={data.meaning}
              onChange={(e) => {
                setData({ ...data, meaning: e.target.value });
                setMeaningInvalid(false);
              }}
              message={MESSAGE.DESCRIPTION_INVALID}
            />
            <div className="chracter-remain">
              Character Remaining {200 - data.meaning.length}/200{" "}
            </div>
          </Col>

          <div className="horizontal-line"></div>

          <Col>
            <div className="textfield-content-label">
              Add pictures<span style={{ color: "red" }}> *</span>
            </div>

            <div className="row">
              {data.picture &&
                data.picture.map((pic, index) => {
                  return (
                    <div key={index}>
                      <img
                        src={pic}
                        alt=""
                        className="procedure-image-wrapper"
                      />
                      <div>
                        <button
                          className="procedure-hospital-icon"
                          onClick={(e) => {
                            handleImageDelete(e, index);
                          }}
                        >
                          <img src={RED_DELETE_ICON} alt="" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              <div className="procedure-image-wrapper">
                {loading ? (
                  <Spinner
                    animation="border"
                    style={{ margin: "52px 0 0 53px" }}
                  />
                ) : (
                  <div
                    className="upload-hospital-image-icon"
                    onClick={handleUploadImage}
                  >
                    <p>
                      <img
                        src={UPLOAD_PLUS}
                        alt=""
                        style={{ paddingLeft: "61px" }}
                      />
                    </p>
                    <p className="upload-label">Add New</p>
                  </div>
                )}
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                  ref={ImageInput}
                />
              </div>
              <Form.Text
                className="text-muted"
                style={{ marginTop: "-5px", width: "400px" }}
              >
                **Please Add JPG or PNG. Pictures should be aligned vertically.
                If posted horizontal, pictures will rotate when exported.
              </Form.Text>
            </div>

            <ContentButton
              title={"Update Picture"}
              onClick={handleUpdate}
              className={"procedure-button"}
              style={{ marginTop: "15px" }}
            />
          </Col>
        </Row>
      </Container>
      <Footer className={"content-management-footer"} />
    </>
  );
};

const mapStateToProps = (state) => {
  try {
    return {
      user: state.user.profile,
      loader: state.loader.mainLoader,
      responseSuccess: state.responseSuccess.responseSuccess,
      picture: state.contentDetails,
      fullHospitals: state.fullHospitals,
      userRole: state.user.profile.role,
      hospitalDetails: state.hospitalDetails,
    };
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleMainLoader: actionCreator.toggleMainLoader,
      response: actionCreator.responseSuccess,
      updatePicture: actionCreator.updatePictures,
      getFullHospitals: actionCreator.getFullHospitals,
      deletePicture: actionCreator.deletePicture, 
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPictures);
