import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { MESSAGE } from "../../../constant";
import "../DailyQuotes/dailyQuotes.css";
import "../../UserManagement/UserManagementClinicianDetails/userManagementClinicianDetails.css";
import AddModalHoc from "../AddModalHoc/addModalHoc";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import moment from "moment";
import { validateChar, validateTextField } from "../../../utils/validations";
import TextField from "../textField";
import { replace, status } from "../../../utils/rename";
import DateField from "../dateField";
import ContentButton from "../contentButton";
import ImageUpload from "../ImageUpload";
import { toast } from "react-toastify";
import MultiSelect from "../../UserManagement/multiSelect";
import { USER_GENDER_DROPDOWN } from "../../../constant";
import { rename } from "../../../utils/rename";
import { components } from "react-select";
import { clone } from "lodash";
import MultiMediaUpload from "../../commonComponent/MultiMediaUpload";

const MenuList = props => {
  return (
    <components.MenuList {...props}>
      <div className="select-style">
        You can select multiple options from the list of hospitals{" "}
      </div>
      {props.children}
    </components.MenuList>
  );
};

const Option = props => {
  return (
    <components.Option {...props}>
      <div>
        {props.children}
        <input
          className="option-custom-input"
          type="checkbox"
          checked={props.isSelected}
          onChange={e => null}
        ></input>
      </div>
    </components.Option>
  );
};

const AddStory = props => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [titleInvalid, setTitleInvalid] = useState(false);
  const [storyInvalid, setStoryInvalid] = useState(false);
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState();
  const [hospitalInvalid, setHospitalInvalid] = useState(false);
  const [media, setMedia] = useState([]);
  const [loader, setLoader] = useState(false);

  const [data, setData] = useState({
    title: "",
    story: "",
    image: "",
    hospital: [],
    hospitalList: [],
  });

  useEffect(() => {
    if (props.isEdit) {

      let hospitalArray = [];
      let hospitalIdArray = [];

      if (
        props.story.hospital &&
        props.story.hospital.length > 0 &&
        options?.length > 0
      ) {
        props.story.hospital &&
          props.story.hospital.forEach(hospital => {
            options.map(option => {
              if (option.value === parseInt(hospital)) {
                hospitalArray.push(option);
                hospitalIdArray.push(option.value);
              }
            });
          });
        }

      setData({
        title: props.story.cellOne,
        story: props.story.story,
        image: props.story.image,
        media_url: props?.story?.media_url,
        hospitalList:
          hospitalArray.length === options?.length - 1
            ? [...hospitalArray, { label: "All", value: "all" }]
            : hospitalArray,
        hospital: hospitalIdArray,
      });
      setMedia(props?.story?.media_url ? props?.story?.media_url : [])
    }else{

      setData({
        ...data,
        hospital : props.userRole.includes("ADMIN") ? data.hospital : [props.hospitalDetails.id]
      })
    }
  }, [options]);



  useEffect(() => {
    props.getFullHospitals();
  }, []);

  useEffect(() => {

    setOptions(
      [
        ...rename(props.fullHospitals.data, "value", "label", "id", "name"),
        { label: "All", value: "all" },
      ].sort((a, b) =>
        (a["label"] || "")
          .toString()
          .localeCompare((b["label"] || "").toString())
      )
    );
  }, [props.fullHospitals.data]);

  const isIncludingString = (string, option) => {
    let result = false;
    if (
      !string ||
      option.label.toString().includes(string) ||
      option.value.toString().includes(string)
    ) {
      result = true;
    }
    return result;
  };

  const setHospital = (opt, { option }) => {
    let newOpts = opt;
    let string = newOpts.value;
    let hospitalArray = [];
    let hospitalDetailsArray = [];
    if (data.hospital?.length === 0) {
      data.hospitalList &&
        data.hospitalList.map(hopital => {
          hospitalArray.push(Number(hopital.id));
          hospitalDetailsArray.push(hopital);
        });
    }
    if (
      data.hospitalList?.pop()?.label === "All" &&
      option &&
      option.value === "all"
    ) {
      hospitalArray = [];
      hospitalDetailsArray = [];
    } else if (option && option.value === "all") {
      let filteredOptions = clone(options);
      filteredOptions = filteredOptions.filter(
        filteredOption =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption)
      );
      newOpts = newOpts
        .concat(filteredOptions)
        .filter(newOpt => newOpt.value !== "all");
      newOpts.map(val => {
        hospitalArray.push(Number(val.value));
        hospitalDetailsArray.push(val);
      });
    } else {
      opt.map(obj => {
        hospitalArray.push(Number(obj.value));
        hospitalDetailsArray.push(obj);
      });
    }
    setHospitalInvalid(false);
    const is_same = () => {
      let count = 0;
      hospitalDetailsArray.map(obj => {
        if (options.includes(obj)) {
          count = count + 1;
        }
      });
      return count;
    };
    if (is_same() === options.length - 1) {
      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [
          ...new Set([...hospitalDetailsArray, { label: "All", value: "all" }]),
        ],
      });
    } else {
      hospitalDetailsArray = hospitalDetailsArray.filter(
        item => item.value !== "all"
      );

      setData({
        ...data,
        hospital: [...new Set(hospitalArray)],
        hospitalList: [...new Set(hospitalDetailsArray)],
      });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmittedOnce(true);
    let validateTitle = validateChar(data.title);
    let validateStory = validateTextField(data.story);
    let validatedHospital = data.hospital.length > 0;
    if (!validateTitle) {
      setTitleInvalid(true);
    }
    if (!validateStory) {
      setStoryInvalid(true);
    }
    if (!validatedHospital) {
      setHospitalInvalid(true);
    }
    if (!validateStory || !validateTitle || !selectedDate || 
      !validatedHospital) {
      return;
    }

    if (loading) {
      toast.error(MESSAGE.UPLOAD_PROGRESS, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if(loader){
      toast.error(MESSAGE.UPLOAD_PROGRESS, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if(data.image.length < 0 || data.image === ""){
      toast.error(MESSAGE.UPLOAD_COVER_IMAGE, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if(media === null || media.length === 0) {
      toast.error(MESSAGE.UPLOAD_IMAGE_VIDEO, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    
    props.toggleMainLoader(true);
    props.addStory({
      user: props.user.idToken,
      title: data.title,
      story: data.story,
      image: data.image,
      media_url: media.length && media,
      scheduled_date: selectedDate
        ? moment(selectedDate).format("YYYY-MM-DD")
        : "",
      status: status(selectedDate),
      hospital: data.hospital,
    });
  };
  
  const handleUpdate = e => {
    e.preventDefault();
    setSubmittedOnce(true);
    let validateTitle = validateChar(data.title);
    let validateStory = validateTextField(data.story);
    let validatedHospital = data.hospital.length > 0;
    if (!validateTitle) {
      setTitleInvalid(true);
    }
    if (!validateStory) {
      setStoryInvalid(true);
    }
    if (!validatedHospital) {
      setHospitalInvalid(true);
    }
    if (!validateStory || !validateTitle || !selectedDate || 
      !validatedHospital) {
      return;
    }

    if (loading) {
      toast.error(MESSAGE.UPLOAD_PROGRESS, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (loader) {
      toast.error(MESSAGE.UPLOAD_PROGRESS, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if(data.image.length < 0 || data.image === ""){
      toast.error(MESSAGE.UPLOAD_COVER_IMAGE, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    
    if(media === null || media.length === 0) {
      toast.error(MESSAGE.UPLOAD_IMAGE_VIDEO, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    props.toggleMainLoader(true);
    props.updateStory(props.story.id, {
      user: props.user.idToken,
      title: data.title,
      story: data.story,
      image: data.image,
      media_url: media.length && media,
      hospital: data.hospital,
    });
  };

  const styles = {
    control: (base, state) => ({
      ...base,
      border: submittedOnce
        ? data.hospital.length === 0
          ? "1px solid #eb5757"
          : 0
        : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(0 123 255 / 25%)" : 0,
      "&:hover": {
        border: submittedOnce
          ? data.hospital.length === 0
            ? "1px solid #eb5757"
            : 0
          : 0,
      },
    }),
    valueContainer: (base, state) => ({
      ...base,
      width: "100%",
      maxWidth: "552px",
      height: "60px",
      backgroundColor: "#f4f4f4",
      borderRadius: "8px",
      boxSizing: "border-box",
      border: "none",
      backgroundImage: `url(${USER_GENDER_DROPDOWN})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "95% 50%",
      marginTop: "2px",
    }),

    indicatorsContainer: (base, state) => ({
      ...base,
      display: "none",

      backgroundColor: "#f4f4f4",

      border: "none",
      backgroundImage: "none",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#5b616b",
      outline: "none",
    }),
    option: (base, state) => ({
      ...base,
      borderBottom: "1px solid #AEB0B5",
      color: "#455674",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      paddingTop: "16px",
      paddingBottom: "16px",
      backgroundColor: "none",
      paddingLeft: "20px",
    }),
  };

  const handleImageDelete = (e, index) => {
    e.preventDefault();
    const arr = [...media];
    arr.splice(index, 1);
    setMedia(arr);
  };


  return (
    <>
      <Form noValidate>

      {props.userRole.includes("ADMIN") ?
          <Row style={{ marginTop: "24px" }}>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                Hospital<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <MultiSelect
                multi={true}
                placeholder={"Select Hospital"}
                options={options}
                styles={styles}
                components={{ MenuList, Option }}
                className="select-hospital-class"
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                onChange={setHospital}
                value={data.hospitalList}
              />
              {submittedOnce ? (
                hospitalInvalid ? (
                  <p className="clinician-invalid">Please choose a category</p>
                ) : null
              ) : null}
            </Form.Group>
          </Col>
        </Row> : 
        
        <Row style={{ marginTop: "24px" }}>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="parent-name">
                Hospital<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <MultiSelect
                multi={true}
                placeholder={"Select Hospital"}
                options={options}
                styles={styles}
                className="select-hospital-class"
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                value={[{"label":props.hospitalDetails.name, "value":props.hospitalDetails.name}]}
                disabled = {true}
              />
              {submittedOnce ? (
                hospitalInvalid ? (
                  <p className="clinician-invalid">Please choose a category</p>
                ) : null
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        }
      
        <Row style={{ marginTop: "24px" }}>
          <Col style={{ paddingRight: "10px" }}>
            <Form.Group controlId="formGroupEmail">
              <Form.Label className="modal-content-label">
                Title <span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Type Or Paste Title Here"
                className="input-field-content"
                value={data.title}
                onChange={e => {
                  setData({ ...data, title: e.target.value });
                  submittedOnce
                    ? setTitleInvalid(!validateChar(e.target.value))
                    : setTitleInvalid(false);
                }}
                isInvalid={titleInvalid}
              />
              <Form.Control.Feedback
                type="invalid"Inspirational story
                className="clinician-invalid"
              >
                {MESSAGE.TITLE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <TextField
          label={"Comfort Positions"}
          className={"text-field-content"}
          labelClassName={"textfield-content-label-glossary"}
          placeholder={"Type Or Paste Comfort Positions Here"}
          isInvalid={storyInvalid}
          value={data.story}
          onChange={e => {
            setData({
              ...data,
              story: e.target.value,
            });
            submittedOnce
              ? setStoryInvalid(
                  !validateTextField(replace(e.target.value)) ? true : false
                )
              : setStoryInvalid(false);
          }}
          message={MESSAGE.STORY_INVALID}
        />

        <ImageUpload
          title={"Upload Cover Image"}
          optional={false}
          data={data}
          setData={res => {
            setData({ ...data, image: res.location });
          }}
          handleImageDelete={() => {
            setData({ ...data, image: null });
          }}
          loading={loading}
          setLoading={setLoading}
        />
        {submittedOnce ? (
          !data.image ? (
            <p className="clinician-invalid">Please upload valid image</p>
          ) : null
        ) : (
          <Form.Text className="text-muted" style={{ marginTop: "-10px" }}>
            **Please Add JPG or PNG. Pictures should be aligned vertically. If posted horizontal, pictures will rotate when exported.
          </Form.Text>
        )}

        <MultiMediaUpload
          data={data}
          setData={setData}
          handleImageDelete={handleImageDelete}
          media={media}
          setMedia={setMedia}
          loader={loader}
          setLoader={setLoader}
        />
        <Row>
          {/* <Col>
            {!props.isEdit ? (
              <DateField
                show={show}
                selectedDate={selectedDate}
                setShow={e => setShow(e.target.checked)}
                setSelectedDate={date => {
                  setSelectedDate(date);
                }}
              />
            ) : null}
          </Col> */}
          <Col className="mt-auto">
            <ContentButton
              onClick={props.isEdit ? handleUpdate : handleSubmit}
              title={props.isEdit ? "Update" : "Publish"}
              className={"podcast-button"}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

const mapStateToProps = state => {
  try {
    return {
      user: state.user.profile,
      loader: state.loader.mainLoader,
      story: state.contentDetails,
      userRole: state.user.profile.role,
      hospitalDetails: state.hospitalDetails,
      fullHospitals: state.fullHospitals,
    };
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reset: actionCreator.reset,
      addStory: actionCreator.addStory,
      toggleMainLoader: actionCreator.toggleMainLoader,
      updateStory: actionCreator.updateStory,
      getFullHospitals: actionCreator.getFullHospitals,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddModalHoc(AddStory));
