import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ROUTES_PATH } from "../../../constant";
import Back from "../../../common/components/back";
import Footer from "../../Footer";
import ContentTable from "../ContentTable/contentTable";
import UserManagementNavbar from "../../UserManagement/userManagementNavbar";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import { Container } from "react-bootstrap";
import { newRename } from "../../../utils/rename";
import "../Procedures/procedures.css";
import DeleteModal from "../deleteModal";
import AddResourcesCategories from "./addResourcesCategories";
import EditHospitalModal from "../../commonComponent/EditHospitalModal";
import { getHospitalCategoriesApi, getParentCategoriesApi, getProfessionalCategoriesApi } from "../../../modules/services";
import { toast } from "react-toastify";

const ResourcesCategory = props => {
  const [publishedPageNumber, setPublishedPage] = useState(1);
  const [parentPageNumber, setParentPage] = useState(1);
  const [ProfessionalPageNumber, setProfessionalPage] = useState(1);

  const [publishedDataList, setPublishedList] = useState();
  const [parentDataList, setParentList] = useState();
  const [professionalDataList, setProfessionalList] = useState();

  const [show, setShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const [parentDeleteModal, setParentDeleteModal] = useState(false);
  const [parentDeleteIndex, setParentDeleteIndex] = useState(0);
  const [ProfesionalDeleteModal, setProfesionalDeleteModal] = useState(false);
  const [ProfesionalDeleteIndex, setProfesionalDeleteIndex] = useState(0);
  const [editCategory, setEditCategory] = useState(false);
  const [showHospitalModal, setShowHospitalModal] = useState(false);
  const [hospitalData, setHospitalData] = useState([])
  const [hospitalFilterData, setHospitalFilterData] = useState('');
  const [parentCsvLoader, setParentCsvLoader] = useState(false);
  const [professionalCsvLoader, setProfesssionalCsvLoader] = useState(false);
  const [hospitalCsvLoader, setHospitalCsvLoader] = useState(false);

  useEffect(() => {
    props.reset();
    props.toggleMainLoader(true);
    props.getPublishedResources(publishedPageNumber,props.role?.includes('SUPERUSER') ? props?.user?.hospital_id : hospitalFilterData);
    props.getParentCategories(parentPageNumber, props.role?.includes('SUPERUSER') ? props?.user?.hospital_id : hospitalFilterData);
    props.getProfessionalCategories(ProfessionalPageNumber, props.role?.includes('SUPERUSER') ? props?.user?.hospital_id : hospitalFilterData);
    setPublishedPage(publishedPageNumber);
    setParentPage(parentPageNumber);
    setProfessionalPage(ProfessionalPageNumber);
    if (props.responseSuccess) {
      setDeleteIndex(0);
      setDeleteModal(false);
      setParentDeleteIndex(0);
      setParentDeleteModal(false);
      setProfesionalDeleteIndex(0);
      setProfesionalDeleteModal(false);
      setShow(false);
    }
  }, [props.match.params.path, props.responseSuccess,hospitalFilterData]);

  useEffect(() => {
    setPublishedList(newRename(props.publishedResources.results, "title"));
  }, [props.publishedResources.results]);

  useEffect(() => {
    setParentList(newRename(props.resources.results, "title"));
  }, [props.resources.results]);

  useEffect(() => {
    setProfessionalList(newRename(props.scheduledResources.results, "title"));
  }, [props.scheduledResources.results]);

  useEffect(() => {
    if(props.openModal){
      setShow(true);
      setEditCategory(true);
      props.openSearchModal(false)
    }
  }, [])

  const headerPublished = {
    headerOne: "Category Title",
  };

  const changePagePublished = selected => {
    setPublishedPage(selected);
    props.toggleMainLoader(true);
    props.getPublishedResources(selected, props.role?.includes('SUPERUSER') ? props?.user?.hospital_id : hospitalFilterData);
  };

  const changePageParent = selected => {
    setParentPage(selected);
    props.toggleMainLoader(true);
    props.getParentCategories(selected, props.role?.includes('SUPERUSER') ? props?.user?.hospital_id : hospitalFilterData);
  };

  const changePageProfessional = selected => {
    setProfessionalPage(selected);
    props.toggleMainLoader(true);
    props.getProfessionalCategories(selected, props.role?.includes('SUPERUSER') ? props?.user?.hospital_id : hospitalFilterData);
  };

  const handleBack = () => {
    props.history.push(ROUTES_PATH.CONTENT_MANAGEMENT);
  };
  const handleAddButton = () => {
    props.toggleMainLoader(false);
    setShow(true);
    setEditCategory(false)
  };

  const handleCancelClose = () => {
    setShow(false);
  };

  //Functions for Edit and delete
  const handleDelete = index => {
    setDeleteIndex(index);
    setDeleteModal(true);
  };
  const handleParentDelete = index => {
    setParentDeleteIndex(index);
    setParentDeleteModal(true);
  };
  const handleProfessionalDelete = index => {
    setProfesionalDeleteIndex(index);
    setProfesionalDeleteModal(true);
  };
  const handleDeleteClose = () => {
    setDeleteIndex(0);
    setDeleteModal(false);
    setParentDeleteIndex(0);
    setParentDeleteModal(false);
    setProfesionalDeleteIndex(0);
    setProfesionalDeleteModal(false);
  };
  const handleDeleteQuotes = () => {
    props.toggleMainLoader(true);
    props.deleteResources(deleteIndex);
  };
  const handleDeleteParent = () => {
    props.toggleMainLoader(true);
    props.deleteParentCategories(parentDeleteIndex);
  };
  const handleDeleteProfesional = () => {
    props.toggleMainLoader(true);
    props.deleteProfessionalCategories(ProfesionalDeleteIndex);
  };

  const handleEdit = (data, category) => {
    setEditCategory(true)
    props.contentDetails({...data, "category": category})
    setShow(true);
  }

  const handleEditClose = () => {
    setShow(false)
    setEditCategory(false)
  }

  const handleHospitalUpdate = (data, category) => {
    setHospitalData(data);
    setShowHospitalModal(true);
    props.contentDetails({...data, "category": category})
  };

  const handleHospitalUpdateClose = () => {
    setShowHospitalModal(false);
  }

  const handelHospitalCategoryDownloadCsv = () => {
      setHospitalCsvLoader(true);
      getHospitalCategoriesApi(publishedPageNumber,props.role?.includes('SUPERUSER') ? props?.user?.hospital_id : hospitalFilterData, true)
      .then(response =>{
        if (response.status === 200 || response.status === 201) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `hospital_resource_category.csv`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setHospitalCsvLoader(false)
          toast.success("Successfully Downloaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(err=> {
        console.error(err)
        setHospitalCsvLoader(false)
        toast.error("Download Failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
  }

  const handelParentCategoryDownloadCsv = () => {
    setParentCsvLoader(true);
    getParentCategoriesApi(parentPageNumber,props.role?.includes('SUPERUSER') ? props?.user?.hospital_id : hospitalFilterData, true)
    .then(response =>{
      if (response.status === 200 || response.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `parent_resource_category.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setParentCsvLoader(false)
        toast.success("Successfully Downloaded", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(err=> {
      console.error(err)
      setParentCsvLoader(false)
      toast.error("Download Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
}

const handelProfessionalCategoryDownloadCsv = () => {
  setProfesssionalCsvLoader(true);
  getProfessionalCategoriesApi(ProfessionalPageNumber, props.role?.includes('SUPERUSER') ? props?.user?.hospital_id : hospitalFilterData, true)
  .then(response =>{
    console.log("here")
    if (response.status === 200 || response.status === 201) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `professional_resource_category.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setProfesssionalCsvLoader(false)
      toast.success("Successfully Downloaded", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  })
  .catch(err=> {
    console.error(err)
    setProfesssionalCsvLoader(false)
    toast.error("Download Failed", {
      position: toast.POSITION.TOP_RIGHT,
    });
  })
}

  return (
    <>
      <Container
        style={{
          minHeight: "100vh",
          position: "relative",
          maxWidth: "1046px",
        }}
      >
        <Back
          Back={handleBack}
          backIconClassName={"parent-back-icon"}
          backNavItemClassName={"parent-back-nav"}
          className="parent-back"
        />
        <UserManagementNavbar
          title={"Resources Categories"}
          isEditButton={false}
          isDeleteButton={false}
          id={"parent-nav-title"}
          className={"parent-navbar"}
          isAddButton={true}
          buttonTitle={"Add Category"}
          handleAddButton={handleAddButton}
          setHospitalFilterData = {setHospitalFilterData}
          isHospitalFilter = {props.role.includes("ADMIN") ? true : false}
        />
        <ContentTable
          mainHeader={"Hospital categories"}
          header={headerPublished}
          dataList={publishedDataList}
          rowPerPage={10}
          itemCountFromServer={props.publishedResources.count}
          changePage={changePagePublished}
          pageNumber={publishedPageNumber}
          minHeightClass={"procedures-minHeight"}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-scheduled-header2"}
          contentHeaderThree={"procedures-scheduled-header3"}
          contentCellOne={"procedures-scheduled-cell1"}
          contentCellTwo={"procedures-scheduled-cell2"}
          contentCellThree={"procedures-scheduled-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={true}
          onDelete={handleDelete}
          onEdit={handleEdit}
          category = {"Hospital"}
          removeCellThree = {true}
          hospitalEdit={true}
          hospitalEditModal = {handleHospitalUpdate}
          isCsvButton = {true}
          handelDownloadCsv = {handelHospitalCategoryDownloadCsv}
          csvLoader = {hospitalCsvLoader}
        />
        <ContentTable
          mainHeader={"Parent categories"}
          header={headerPublished}
          dataList={parentDataList}
          rowPerPage={10}
          itemCountFromServer={props.resources.count}
          changePage={changePageParent}
          pageNumber={parentPageNumber}
          minHeightClass={"procedures-minHeight"}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-scheduled-header2"}
          contentHeaderThree={"procedures-scheduled-header3"}
          contentCellOne={"procedures-scheduled-cell1"}
          contentCellTwo={"procedures-scheduled-cell2"}
          contentCellThree={"procedures-scheduled-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={true}
          onDelete={handleParentDelete}
          onEdit={handleEdit}
          category = {"Parent"}
          removeCellThree = {true}
          hospitalEdit={true}
          hospitalEditModal = {handleHospitalUpdate}
          isCsvButton = {true}
          handelDownloadCsv = {handelParentCategoryDownloadCsv}
          csvLoader = {parentCsvLoader}
        />

        <ContentTable
          mainHeader={"Professional categories"}
          header={headerPublished}
          dataList={professionalDataList}
          rowPerPage={10}
          itemCountFromServer={props.scheduledResources.count}
          changePage={changePageProfessional}
          pageNumber={ProfessionalPageNumber}
          minHeightClass={"procedures-minHeight"}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-scheduled-header2"}
          contentCellOne={"procedures-scheduled-cell1"}
          contentCellTwo={"procedures-scheduled-cell2"}
          contentCellThree={"procedures-scheduled-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={true}
          onDelete={handleProfessionalDelete}
          onEdit={handleEdit}
          category = {"Professional"}
          removeCellThree = {true}
          hospitalEdit={true}
          hospitalEditModal = {handleHospitalUpdate}
          isCsvButton = {true}
          handelDownloadCsv = {handelProfessionalCategoryDownloadCsv}
          csvLoader = {professionalCsvLoader}
        />

        <Footer className={"content-management-footer"} />
        <AddResourcesCategories
          show={show}
          contentClassName={"modal-content-quotes"}
          title={editCategory ? "Edit Categories" : "Add Categories"}
          handleCancelClose={editCategory ? handleEditClose : handleCancelClose}
          isAddResources={true}
          isEdit = {editCategory}
        />
        <DeleteModal
          modal={deleteModal}
          handleClose={handleDeleteClose}
          handleDelete={handleDeleteQuotes}
          loader={props.loader}
        />
        <DeleteModal
          modal={parentDeleteModal}
          handleClose={handleDeleteClose}
          handleDelete={handleDeleteParent}
          loader={props.loader}
        />
        <DeleteModal
          modal={ProfesionalDeleteModal}
          handleClose={handleDeleteClose}
          handleDelete={handleDeleteProfesional}
          loader={props.loader}
        />
        <EditHospitalModal 
          show={showHospitalModal}
          title = {"Update Hospital"}
          contentClassName={"modal-content-quotes"}
          handleCancelClose = {handleHospitalUpdateClose}
          hospitalData = {hospitalData}
          setShow = {setShowHospitalModal}
          isResourceCategory = {true}
        />
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return {
    responseSuccess: state.responseSuccess.responseSuccess,
    publishedResources: state.publishedResources,
    scheduledResources: state.scheduledResources,
    resources: state.resources,
    loader: state.loader.mainLoader,
    openModal : state.modalFromSearch.openModal,
    role: state.user.profile.role,
    user: state?.user?.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getPublishedResources: actionCreator.getHospitalCategories,
      getProfessionalCategories: actionCreator.getProfessionalCategories,
      getParentCategories: actionCreator.getParentCategories,
      reset: actionCreator.reset,
      toggleMainLoader: actionCreator.toggleMainLoader,
      approveResources: actionCreator.approveResources,
      deleteResources: actionCreator.deleteHospitalCategories,
      deleteParentCategories: actionCreator.deleteParentCategories,
      deleteProfessionalCategories: actionCreator.deleteProfessionalCategories,
      contentDetails: actionCreator.getContentDetails,
      openSearchModal : actionCreator.isOpenModalFromSearch,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesCategory);
