import React from "react";
import { Navbar, Button } from "react-bootstrap";
import {
  EDIT_ICON,
  DELETE_ICON,
  ADD_ICON,
  BUTTON_SPINNER,
} from "../../constant";
import "./userManagementNavbar.css";
import Search from "../NavBar/search";
import HospitalDropdown from "../commonComponent/HospitalDropdown";

const UserManagementNavbar = (props) => {
  const {
    id,
    className,
    title,
    isEditButton,
    isSaveChangesButton,
    isDeleteButton,
    handleEditButton,
    handleSaveChangesButton,
    handleDeleteButton,
    showLoader,
    loaderheight,
    loaderImg,
    deleteId,
    isAddButton,
    disabled,
    handleAddButton,
    buttonTitle,
    activationLabel,
    isSearch,
    searchValue,
    searchOnChange,
    isHospitalFilter,
    setHospitalFilterData,
    // isCsvButton,
    // handelDownloadCsv,
    // csvLoader
  } = props;
  return (
    <>
      <Navbar className={className}>
        <Navbar.Brand id={id}>{title}</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {(() => {
            if (isEditButton) {
              return (
                <button
                  disabled={disabled}
                  onClick={() => handleEditButton(deleteId)}
                  className="navbar-edit-button"
                >
                  {activationLabel}
                </button>
              );
            }
          })()}
          {(() => {
            if (isDeleteButton) {
              return (
                <button
                  disabled={disabled}
                  onClick={() => handleDeleteButton(deleteId)}
                  className="navbar-delete-button"
                >
                  <img
                    src={DELETE_ICON}
                    style={{ marginRight: "12px" }}
                    alt=""
                  />{" "}
                  Delete
                </button>
              );
            }
          })()}
          {(() => {
            if (isSaveChangesButton) {
              return (
                <Button
                  disabled={disabled}
                  onClick={() => {
                    handleSaveChangesButton(deleteId);
                  }}
                  // className="navbar-save-button"
                  bsPrefix={"navbar-save-button"}
                >
                  {" "}
                  {showLoader && (
                    <img src={loaderImg} height={loaderheight} alt="" />
                  )}{" "}
                  {!showLoader && <span>Save Changes</span>}{" "}
                </Button>
              );
            }
          })()}
          {(() => {
            if (isHospitalFilter) {
              return (
                <div className="hospital-dropdown-container">
                <HospitalDropdown 
                setHospitalFilterData = {setHospitalFilterData}
                />
                </div>
              );
            }
          })()}
          {/* {(() => {
            if (isCsvButton) {
              return (
                <button
                  className="csv-button-css"
                  disabled={disabled}
                  onClick={handelDownloadCsv}
                >
                  {" "}
                  {csvLoader && (
                    <img src={loaderImg} height={loaderheight} alt="" />
                  )}{" "}
                  {!csvLoader && (
                    <span>
                      {" "}
                      Download Csv
                    </span>
                  )}{" "}
                </button>
              );
            }
          })()} */}
          {(() => {
            if (isAddButton) {
              return (
                <button
                  className="add-button-css"
                  disabled={disabled}
                  onClick={handleAddButton}
                >
                  {" "}
                  {showLoader && (
                    <img src={loaderImg} height={loaderheight} alt="" />
                  )}{" "}
                  {!showLoader && (
                    <span>
                      {" "}
                      <img
                        src={ADD_ICON}
                        style={{ marginRight: "12px" }}
                        alt=""
                      />
                      {buttonTitle}
                    </span>
                  )}{" "}
                </button>
              );
            }
          })()}
          {
            (()=> {
              if(isSearch){
                return(
                  <>
                  <Search 
                  value = {searchValue}
                  onChange = {searchOnChange}
                  />

                  </>
                )
              }
            })()
          }
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

UserManagementNavbar.defaultProps = {
  showLoader: false,
  loaderheight: "30px",
  loaderImg: BUTTON_SPINNER,
};
export default UserManagementNavbar;
