import leftArrow from "../assets/images/LeftArrow.svg";
import profileIcon from "../assets/images/profileIcon.svg";
import logOut from "../assets/images/logOut.svg";
import backIcon from "../assets/images/back.svg";
import search from "../assets/images/search.svg";
import filter from "../assets/images/filter.svg";
import addIcon from "../assets/images/Add_icon.svg";
import viewIcon from "../assets/images/eye 1.svg";
import Spinner from "../assets/images/SpinnerLoader.svg";
import EyeOpen from "../assets/images/eye.svg";
import EyeOff from "../assets/images/eyeOff.svg";
import LogoutIcon from "../assets/images/logoutIcon.svg";
import expandMoreOppIcon from "../assets/images/expandMore.png";
import clocLogo from "../assets/images/clocLogo1.svg";

import userDrop from "../assets/images/userGenderDropdown.svg";
import clocChildImage from "../assets/images/clocChildImage.png";
import editIcon from "../assets/images/edit 1.svg";
import planEditIcon from "../assets/images/edit 2.svg";
import deleteIcon from "../assets/images/trash-2 2.svg";
import redDeleteIcon from "../assets/images/redDeleteIcon.svg";
import parentChildDetailsProfileIcon from "../assets/images/parentChildDetailsProfileIcon.svg";
import ParentChildDetailsUploadProfileIcon from "../assets/images/ParentChildDetailsUploadProfileIcon.svg";
import globalSearchIconUnique from "../assets/images/globalSearchIconUnique.svg";
import leftScroll from "../assets/images/leftScroll.svg";
import rightScroll from "../assets/images/rightScroll.svg";
import uploadPlus from "../assets/images/uploadPlus.svg";
import redCross from "../assets/images/x 2.svg";
import addUserIcon from "../assets/images/person-add.svg";
import MainSpinner from "../assets/images/mainLoader.svg";
import uploadSmallIcon from "../assets/images/uploadSmallIcon.svg";
import successIcon from "../assets/images/checked 1.svg";
import deleteWarning from "../assets/images/deleteWarning.svg";
import circlePhoto from "../assets/images/circlePhoto.svg";
import contentApproveIcon from "../assets/images/contentTickIcon.svg";
import userGenderDropdown from "../assets/images/userGenderDropdown.svg";
import uploadAudio from "../assets/images/uploadAudio.svg";
import viewEye from "../assets/images/viewEye.svg";
import errorImage from "../assets/images/errorImage.svg";
import networkErrorImage from "../assets/images/networkErrorImage.svg";
import save from "../assets/images/save.svg";
import Edit_Hospital from "../assets/images/Edit_Hospital.svg"
import Upload_File from "../assets/images/Upload_File.svg"
import Copy_Hospital from "../assets/images/Copy_Hospital.svg";

export const MAIN_SPINNER = MainSpinner;
export const USER_DROPDOWN = userDrop;
export const BUTTON_SPINNER = Spinner;
export const EYE_OPEN = EyeOpen;
export const EYE_OFF = EyeOff;
export const LOGOUT_ICON = LogoutIcon;
export const EXPAND_MORE_ICON_OPP = expandMoreOppIcon;
export const CLOC_LOGO = clocLogo;
export const CLOC_CHILD_IMAGE = clocChildImage;
export const LEFT_ARROW = leftArrow;
export const PROFILE_ICON = profileIcon;
export const LOG_OUT = logOut;
export const BACK_ICON = backIcon;
export const SEARCH_ICON = search;
export const FILTER_ICON = filter;
export const ADD_ICON = addIcon;
export const VIEW_ICON = viewIcon;
export const EDIT_ICON = editIcon;
export const EDIT_ICON2 = planEditIcon;
export const DELETE_ICON = deleteIcon;
export const PARENT_CHILD_DETAILS_PROFILE_ICON = parentChildDetailsProfileIcon;
export const PARENT_CHILD_DETAILS_UPLOAD_PROFILE_ICON =
  ParentChildDetailsUploadProfileIcon;
export const GLOBAL_SEARCH_ICON_UNIQUE = globalSearchIconUnique;
export const RED_DELETE_ICON = redDeleteIcon;
export const LEFT_SCROLL_ICON = leftScroll;
export const RIGHT_SCROLL_ICON = rightScroll;
export const UPLOAD_PLUS = uploadPlus;
export const CROSS_ICON = redCross;
export const ADD_USER_ICON = addUserIcon;
export const UPLOAD_SMALL_ICON = uploadSmallIcon;

export const DELETE_WARNING = deleteWarning;

export const SUCCESS_ICON = successIcon;
export const CIRCLE_PHOTO = circlePhoto;
export const CONTENT_APPROVE_ICON = contentApproveIcon;
export const USER_GENDER_DROPDOWN = userGenderDropdown;
export const UPLOAD_AUDIO = uploadAudio;
export const VIEW_EYE = viewEye;
export const ERROR_IMAGE = errorImage;
export const NETWORK_ERROR_IMAGE = networkErrorImage;
export const SAVE_HOSPITAL_DATA = save 
export const EDIT_HOSPITAL = Edit_Hospital
export const UPLOAD_FILE = Upload_File;
export const COPY_HOSPITAL = Copy_Hospital;